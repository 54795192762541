import React from "react";

export default function AboutCompany() {
  return (
    <>
      <style>{css}</style>
      <div className="AboutCompany">
        <div className="AboutCompany-content">
          <div className="AboutCompany-content-header">
            <h2>Adventure with Confidence</h2>
            <h6>
              Experience the Magic: 98% of Customers Choose Us for Another
              Adventure!
            </h6>
          </div>
          <div className="AboutCompany-content-grid">
            <ul>
              <li>
                <div className="AboutCompany-content-grid-img">
                  <img
                    src={
                      require("../Images/Home/AboutCompany/mountains-altitude-svgrepo-com (1).svg")
                        .default
                    }
                    alt=""
                    
                  />
                </div>
                <h4>Memorable Journeys</h4>
                <p>
                  Our Tours Offer Big Wows and Hidden Gems, Ensuring Genuine
                  Exploration and Lasting Memories.
                </p>
              </li>
              <li>
                <div className="AboutCompany-content-grid-img">
                  <img
                    src={require("../Images/Home/AboutCompany/icons8-tour-guide-64.png")}
                    alt=""
                    color="white"
                  />
                </div>
                <h4>Expert Native guides</h4>
                <p>Curated for In-Depth Insight and Passionate Exploration.</p>
              </li>
              <li>
                <div className="AboutCompany-content-grid-img">
                  <img
                    src={require("../Images/Home/AboutCompany/icons8-handshake-heart-64.png")}
                    alt=""
                  />
                </div>
                <h4>Responsible at heart</h4>
                <p>
                  Our distinctive approach, featuring flexible bookings and a
                  commitment to sustainability sets us apart.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
const css = `
    .AboutCompany{
        height:65vh;
        width:100%;
        background-color: #344e59;
        background-color: #d1d1d1;
        background-color: #344e59;
        color:#fff;
        display: flex;
        justify-content: center;
        align-items: center;
        // background:#7a8764;
    }
    .AboutCompany-content-grid-img img{
      height:90px;
      width:90px;
    }
    .AboutCompany-content{
        width:90%;
        height:90%;
        margin:auto;
    }
    .AboutCompany-content-header{
      width: 90%;
      margin: 0 auto;
    }
    .AboutCompany-content h2{
        font-size:50px;
        padding-top:3%;
    }
    .AboutCompany-content h6{
        font-size:20px;
    }
    .AboutCompany-content-grid{
        width:90%;
        height:90%;
        margin:auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .AboutCompany-content-grid ul{
        width:100%;
        list-style:none;
        display:flex;
        justify-content:space-between;
        padding:0;
    }
    .AboutCompany-content-grid ul li{
        height:100%;
        width:30%;
        text-align:center;
    }
    .AboutCompany-content-grid ul li h4{
        font-size:16px;
        font-weight:bold;
        padding-top:2%;
    }
    .AboutCompany-content-grid ul li p{
        font-size:14px;
        padding-top:2%;
    }
    @media (max-width:560px){
      .AboutCompany{
        height:45vh;
      }
      .AboutCompany-content h2{
        font-size:18px;
      }
      .AboutCompany-content h6{
        font-size:13px;
      }
      .AboutCompany-content-grid-img img{
        width:50px;
        height:50px;
      }
      .AboutCompany-content-grid ul li h4{
        font-size:13px;
      }
      .AboutCompany-content-grid ul li p{
        font-size:10px;
      }
    }
`;
