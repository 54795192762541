import React from "react";

export default function Footer(){
    return(
        <>
            <style>
                {css}
            </style>
            <div className="footer-container"> 
                <div className="social-icon">
                    <h3>Connect Us</h3>
                    <div className="social-icon-img">
                        <img src={require('../Images/Home/Footer/facebook.png')} alt="" width={40} />
                        <img src={require('../Images/Home/Footer/instagram.png')} alt="" width={40} />
                        <img src={require('../Images/Home/Footer/twitter.png')} alt="" width={40}/>
                    </div>
                </div>
                <div className="footer-list">
                    <ul>
                        <li>RESPONSIBLE TOURSIM</li>
                        <li>TERMS & CONDITIONS</li>
                        <li>PRIVACY POLICY</li>
                        <li>SITE MAP</li>
                        <li>CONTACT US</li>
                    </ul>
                </div>
                <div className="quote">
                    <div className="quote-content">
                If you are looking for a trekking, walking cycling holiday, kayaking expedition in the backwaters, wildlife & birding or family activity holidays in Kerala & other regions of India,
                 we have a wide range of tours to choose from !
                    </div>
                </div>
                <div className="copyright">
                    <div className="copyright-content">
                        © thetourpilot.com 2023
                    </div>
                </div>
                
            </div>
        </>
    )
}

const css = `
    .footer-container{
        height : 50vh;
        width : 100%;
        background-color :#2d3e45 ;
    }
    .footer-container h3{
        padding-top:2%;
        text-align : center;
        color:#fff;
    }
    .social-icon-img{
        width:250px;
        display:flex;
        justify-content: space-between;
        margin:auto;
        margin-top:30px;
    }
    .footer-list{
        display:flex;
        justify-content: center;
        
    }
    .footer-list ul{
        list-style:none;
        display:flex;
        color:#fff;
        justify-content: space-between;
        width:800px;
        margin-top:30px;
        padding-left:0px;
    }
    .quote{
        display:flex;
        justify-content: center;
        margin-top:30px
    }
    .quote-content{
        color:#fff;
        width:70%;
        text-align:center;
    }
    .copyright{
        color:#fff;
        text-align:center;
        margin-top:30px;
    }
    @media (max-width:576px){
        .footer-container{
            height : 37vh;
            width : 100%;
        }
        .footer-container h3{
            padding-top:2%;
            text-align : center;
            color:#fff;
            font-size: 14px;
        }
        .social-icon-img{
            width:190px;
        }
        .social-icon-img img{
            width:25px;
        }
        .footer-list{
            display:flex;
            justify-content: center;
            
        }
        .footer-list ul{
            list-style:none;
            display:flex;
            color:#fff;
            justify-content: space-between;
            width:80%;
            margin-top:30px;
            font-size:10px;
            text-align:center;
        }
        .quote{
            display:flex;
            justify-content: center;
            margin-top:30px;
            font-size:10px;
        }
        .quote-content{
            color:#fff;
            width:70%;
            text-align:center;
        }
        .copyright{
            color:#fff;
            text-align:center;
            margin-top:30px;
            font-size:13px;
        }
    }
`