import React, { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';


export default function Booking() {
  const [show, setShow] = useState(false);
  const focusColumn = useRef(null);
  const [Toggle,setToggle] = useState(false)


  const formSuccessMessage = () =>{
      setToggle(!Toggle)
  }

  useEffect(()=>{
    focusColumn.current.focus();
  },[show])

  function togglePopup() {
    return setShow(!show);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5hz58yp', 'template_ub07fi5', form.current, '7u1HoS3e5lwOwxDQG')
      .then((result) => {
          console.log(result.text);
          formSuccessMessage();
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();
  };

  
  function Popup() {
    return (
      <>
        <style>{css}</style>

        <div
          className={showClass}
          style={{
            width: "100%",
            height: "150%",
          }}
        >
        <form
          className={"innerContent  showClass"}
          
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="boxHeader">
            <div className="boxHeader-left">
              <h6>SEND ENQUIRY</h6>
              {/* <p>We will get in touch soon</p> */}
            </div>
            <div className="boxHeader-right">
              <button onClick={togglePopup}>
                <img
                  src={
                    require("../Images/Booking/close-error-svgrepo-com.svg")
                      .default
                  }
                  alt=""
                  style={{
                    width: "10px",
                    height: "10px",
                    color: "black",
                    cursor:"pointer"
                  }}
                />
              </button>
            </div>
          </div>
          <div className="boxContent">
            <div className="ContentWrapper">
              <div className="inputBox" >
                <label htmlFor="">Full Name</label> <br />
                <input
                  type="text"
                  name="name"
                  id="guestname"
                  required
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                  ref={focusColumn}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Email</label>
                <br />
                <input
                  type="email"
                  name="email"
                  id="guestemail"
                  required
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Contact No:</label>
                <br />
                <input
                  type="number"
                  name="contact"
                  id="guestcontactnumber"
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Number of Pax</label>
                <br />
                <input
                  type="number"
                  name="pax"
                  id="guestpax"
                  required
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Nationality</label>
                <br />
                <input
                  type="text"
                  name="nationality"
                  id="guestnationality"
                  required
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Travel Date</label>
                <br />
                <input
                  type="date"
                  name="date"
                  id="guestdate"
                  required
                  style={{
                    width: "90%",
                    height: "30px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <div className="inputBox">
                <label htmlFor="">Tell us you requirment</label>
                <br />
                <input
                  type="text"
                  name="description"
                  id="description"
                  required
                  style={{
                    width: "90%",
                    height: "60px",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>

              <button  className="sendButton" value="send" type="submit">Send Enquiry</button>
            </div>
          </div>
        </form>
        {Toggle && 
        <div className="formpage-modal">
          <div className="form-outer-layer">
            {/* <div className="form-model-header">
    
            </div> */}
            <div className="form-content-body"><i class="fa-sharp fa-solid fa-circle-check"></i></div>
            <span className="form-content-body">Success</span>
            <div className="form-content-body">Await Reply</div>
            <button className="font-content-button" onClick={()=>{togglePopup();formSuccessMessage()}}>Continue</button>
          </div>
        </div>}
        </div>
      </>
    );
  }

  const showClass = show ? "bookingBox" : "bookingHide";

  return (
    <>
      <button onClick={togglePopup} className="enquiryButton">
        BOOK/ENQUIRE
      </button>
      <Popup />
    </>
  );
}
const css = `
.form-content-body{
  padding:20px;
  color:#fff;
  text-align:center;
  font-size:25px;
  font-weight:bold;
}
.form-content-body i{
  font-size:80px;
}
.form-outer-layer{
  width: 300px;
  height:300px;
  background: #344e59;
  border-radius: 10px; 
  overflow:hidden;
  padding:30px;
  text-align:center;
}
.font-content-button{
  height: 35px;
  width: 120px;
  background: linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  border-radius: 6px;
}
.font-content-button:hover{
  background-position:left bottom;
color:#fff;
box-shadow: 0 0 10px 0 #000;
background-color:#2d3e45
}
    .formpage-modal{
      left: 0px;
      top: 0px;
      position: fixed;
      z-index: 9999999999;
      width: 100vw;
      height: 100vh;
      background: #a1a1a147;
      display: flex;
      justify-content: center;
      align-items: center;
    }

.enquiryButton{
  margin-top:5px;
  height:30px;
  width:140px;
  cursor: pointer;
  border-radius:10px;
  background: linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;  
  }
  .enquiryButton:hover {
    background-position:left bottom;
    color:#fff;
    box-shadow: 0 0 10px 0 #000;
    background-color:#2d3e45
  }
.innerContent::-webkit-scrollbar {
  display: none;
}

.sendButton{
  margin:40px 0;
  border-radius:10px;
  height: 40px;
  width: 120px;
  background: linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;  
}
.sendButton:hover {
  background-position:left bottom;
  color:#fff;
  box-shadow: 0 0 10px 0 #000;
  background-color:#2d3e45
}
    .bookingBox{
      display:block;
      position:fixed;
      z-index:9;
      top:0;
      left:0;
      background-color:#344e597a;
      }
    .bookingHide{
      display:none;
    }
    .innerContent{
      border-radius:40px;
      background-color:#344e59;
      position:fixed;
      top:12%;
      right:10%;
      Z-INDEX:999;
      overflow:auto;
      width: 600px;
      height: 85%;
      
    }
    .bookingHide{
      display:none;
    }
    .boxHeader{
      display:flex;
      justify-content:space-between;
      padding:30px;
    }
    .boxContent{
      background-color:#344e59;
      overflow:hidden;
      
    }
    .ContentWrapper{
      max-width:80%;
      margin:auto;
      // padding-top:30px;
      
    }
    .inputBox{
      width:100%;
      margin-top:30px
    }
    h6{
      color:#fff;
      font-size:20px;
    }
    p{
      color:#fff;
      font-size:20px;
    }
    label{
      color:#fff;
    }
    @media (max-width:576px){
      .innerContent {
        border-radius: 40px;
        background-color: #344e59;
        position: fixed;
        top: 8%;
        right: 8%;
        Z-INDEX: 999;
        overflow: auto;
        width: 320px;
        height: 85%;
    }
    .form-outer-layer{
      width: 250px;
      height:250px;
    }
    }
`;
