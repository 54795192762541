import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/KochiGoa/Banner/GoaSurf.jpg";
import Footer from "../../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Booking";
import FAQ from "../FAQ";
// import 'react-tabs/style/react-tabs.css';
// page-id = 3

export default function KochiGoa() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>Coastal Cruise: Motorcycle Adventure from Calicut to Goa</h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  Way back in the mid 1600’s this holy man, Baba Budan brought
                  back seven germinable coffee beans with him, while returning
                  from a pilgrimage in Arabia. Since then, coffee plants have
                  been flourishing in the hilly region of not just
                  Chikmagalur—where he planted them—but the entire region of
                  Coorg. The Baba Budan range is steeped in history and its
                  slopes are redolent with green bushes of fine Arabica and
                  Robusta, the two variants of coffee grown here. We will be
                  following his path to explore the coffee trails in India.
                </p>
                <p>
                  This is a motorcycling adventure from Calicut to Goa, which
                  will take you through some of the best stretches along South
                  India covering three South Indian states. Enjoy a good climate
                  and roads with less cars as you ride through these lands of
                  breathtaking scenery and rich culture. Experience all kinds of
                  natural wonders along the route from mountains, forests,
                  waterfalls to beaches. Enjoy the wonders of Kerala, Karnataka
                  and Goa and see what makes each state unique.
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Travel Sketch</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Property</th>
                      <th>Meal Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Day 1</td>
                      <td>Calicut</td>
                      <td>Wayanad Wild</td>
                      <td> -/-/D</td>
                    </tr>
                    <tr>
                      <td>Day 2</td>
                      <td>Wayanad</td>
                      <td>Wayanad Wild</td>
                      <td>B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 3</td>
                      <td> Coorg</td>
                      <td>The Wind Flower Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 4</td>
                      <td>Hassan</td>
                      <td>Hoysala Village</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 5</td>
                      <td>Mudigere</td>
                      <td>Balur Coffee Estate</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 6</td>
                      <td>Koppa</td>
                      <td>Creepe Ginger</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 7</td>
                      <td>Kundapura</td>
                      <td> UVA Meridian</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 8</td>
                      <td>Gokarna</td>
                      <td>Sunskruti Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 9</td>
                      <td>South Goa</td>
                      <td>Dwaraka Eco Beach Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 10</td>
                      <td>South Goa</td>
                      <td>Dwaraka Eco Beach Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 11</td>
                      <td>Depart</td>
                      <td> </td>
                      <td> B/-/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>Riding along West coast to Goa.</li>
                  <li>Riding through the Rain forests at Agumbe.</li>
                  <li>Experience Kerala Ayurvedic Message</li>
                  <li>Tea-plantations and spice plantation walks</li>
                  <li>
                    Motorcycle ride along the oldest Coffee trails in India
                  </li>
                  <li>Golden Temple visit</li>
                  <li>
                    Exquisite healthy and local Kerala & Karnataka food all
                    along.
                  </li>
                  <li>Professional Tour guide , Mechanic & local experts.</li>
                  <li>Coffe factory Visit</li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>Day 1: Calicut</h6>
                  <p>
                    Pickup from Calicut International airport transfer to your
                    hotel at Wayanad. The rest of it slow is to relaxation –
                    beat jet lag and get set for your cycling journey in the
                    following few days. Evening fix your cycles and tour
                    briefing followed by dinner.
                  </p>
                </li>
                <li>
                  <h6>Day 2: Wayanad</h6>
                  <p>
                    After breakfast we will start explore around Wayanad by
                    bike. Wayanad is all about alluring greenery all along the
                    countryside with exquisite misty mountains, splendid forest
                    and wildlife, tempting coffee and tea estates, pristine
                    rivers and cascading waterfalls and folk culture. The
                    Western Ghats Mountain ranges bind Wayanad to the nature.
                    Wayanad is a requisite to its heritage and culture, for a
                    traveler who can find this hillside paradise as a bewitching
                    charm for its traditions, customs and wildlife. The
                    aesthetic land of spices, Wayanad is the perfect spot for
                    your getaways. You can find true delicacy in wayanadan
                    mystic lands with amusing nature and beauty of the wild.
                  </p>
                </li>
                <li>
                  <h6>Day 3: Coorg</h6>
                  <p>
                    Today we will be riding to Coorg the land of Cauvery, Coorg
                    is a district of Karnataka and is known for its scenic
                    beauty, adventures and great hospitality. The place is
                    regarded as the ‘Kashmir of the South’ as Coorg is
                    surrounded by a lot of beautiful hills making it an
                    adventurous site too. After sixty kilomeeters ride we will
                    bw transferring to hotel at Coorg.
                  </p>
                </li>
                <li>
                  <h6>Day 4: Hassan</h6>
                  <p>
                    Today, you cruise through country roads shaded by the ‘Flame
                    of the Forest’ trees and the occasional Banyan and coconut
                    groves. You get to witness the beauty and unique culture of
                    rural India first-hand on this ride to the historic town of
                    Hassan, where you will be staying at a beautiful theme
                    resort.
                  </p>
                </li>
                <li>
                  <h6>Day 5: Mudigere</h6>
                  <p>
                    Today’s riding route has two very special stopovers, Belur
                    and Halebid. Belur is home to the Chennakeshava temple,
                    which is one of the finest examples of Hoysala architecture
                    with Halebid, the capital of the Hoysala Empire. The
                    temples, Hoysaleshwar and Kedareshwara, built in 1121 AD,
                    stand testament to the architectural excellence of the
                    Hoysala Empire. The elaborately detailed carvings in stone
                    adorning the temple facade and the monolithic Nandi, the
                    mount of Lord Shiva ‘guarding’ the entrance, and the
                    detailed carvings that even showcase the jewellery and dance
                    positions of the dancers are some of the fascinating
                    features of these temples. These temples are UNESCO World
                    Heritage sites. Once you have had your fill of architecture,
                    you will continue cycling to Mudigere in the hills. Here,
                    you can experience the unspoilt countryside with its rolling
                    mist covered hills, thick forests, coffee plantations,
                    farmsteads around which wild flowers grow in abundance and
                    also spot beautifully plumaged Orange Minivets and Ioras
                    during your lazy walks here. You will retire for the night
                    at a well-appointed homestay here.
                  </p>
                </li>
                <li>
                  <h6>Day 6: Koppa</h6>
                  <p>
                    You will start today’s ride from Balur Estate through thick
                    forests for 25 kms. Thereafter, there is a fascinating ride
                    downhill, towards your destination Koppa, a small town
                    famously known as the Kashmir of Karnataka for its gentle
                    weather and fascinating scenery. Encircled by the Western
                    Ghats, this is a lovely place located at a height of 800
                    meters above sea level. You will stay at a small homestay,
                    which is basic but very welcoming.
                  </p>
                </li>
                <li>
                  <h6>Day 7: Kundapura</h6>
                  <p>
                    Next day, your ride will progress through Agumbe village,
                    famous for large population of King Cobras. The ride is a
                    long one; hence you will be riding part of the way & then
                    transfer to your destination. You will cycle till Kundapura
                    town which is surrounded by water from three sides. Towards
                    the north lies the Panchagangavali River. Towards the east
                    lies the Kalaghar River. Towards the west lie the Kodi back
                    waters and the Arabian Sea, south side is the only side as
                    the main connecting land mass.
                  </p>
                </li>
                <li>
                  <h6>Day 8: Gokarna</h6>
                  <p>
                    You will pedel down the mountains & join the coastal roads,
                    towards Gokarna, a small and remote holy town, with four of
                    India’s most secluded and pristine beaches nestled nearby.
                    Gokarna’s main attractions are its beaches, where people
                    come to chill and soak up the sun for months at a time. You
                    will ride on this day along the coastal route, heavily
                    scented with the smell of the sea and sand, to reach the
                    resort. At night you will savour delightful dinner at a
                    special restaurant.
                  </p>
                </li>
                <li>
                  <h6>Day 9: Goa</h6>
                  <p>
                    Once again, you ride along the scenic beach side with cliffs
                    dropping off to the beautiful beaches below. This is also
                    known as the ‘mango’ belt for the delicious ‘Alphonso’
                    Mangoes that grow in profusion here. Karwar an ancient site
                    of sea trade frequented by the Arabs, Dutch, Portuguese,
                    French and later the British is historically significant.
                    The picturesque Sadashivgad Fort is located by the Kali
                    River on which Karwar stands. From here you will ride into
                    the beautiful beaches of South Goa.
                  </p>
                </li>
                <li>
                  <h6>Day 10: Goa</h6>
                  <p>
                    Today the last day of cycling is also the last day when you
                    can take in the beauty of Goa. Your ride takes you to the
                    Northern parts of Goa. En-route you will also visit the
                    villages of interior Goa, temples, churches, historic
                    bungalows. You can also take time off to visit the Bom Jesus
                    Cathedral, an expression of Portuguese sovereignty for 400
                    years colonial rule. You will finish the ride & delicious
                    Goan cuisine and the laid back ambience awaits you.
                  </p>
                </li>
                <li>
                  <h6>Day 11: Departure</h6>
                  <p>
                    After the delicious breakfast from the Hotel you will be
                    transferred to the airport at Goa to bid adieu to Goa
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/untitled1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/ghat.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/jog_falls.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/IMG_20190115_180644.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
         <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:100;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
        margin-top:5px;
        font-weight:100;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        margin-top:20px;
      }
      
    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      @media(max-width:576px){
        .react-tabs__tab-list{
          flex-direction: column;
          height:209px; 
          width: 86%;
        }
        .AboutTour {
          padding-top: 40px;
          padding-bottom: 40px;
          width: 92%;
      }
      .AboutTour-content p{
        font-size: 12px;
      }
      .Highlights{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 92%;
      }
      .TourPage-Overview {
        font-size: 10px;
      }
      .Highlights ul {
        font-size: 13px;
      }
      .AboutTour-ul {
        height: 27vh;
        width: 76%;
      }
      .AboutTour-ul ul{
        padding-left: 10px;
      }
      .TourPage-Title h1{
        font-size: 1.3em;
      }
      .Itinerary {
        width:100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .wrapper {
        padding-right: 10px;
        padding-left: 2px;
      }
      .Itinerary-content ul{
        padding-top: 5px;
        padding-left:30px;
      }
      .Itinerary-content h6{
        font-size: 20px;
    }
    .Itinerary-content p{
      font-size: 15px;
    }
    .gallery img{
      width:300px;
      height:180px;
    }
    }
    }
`;
