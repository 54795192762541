import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/BikeTrekKayak/Banner/IMG_20190303_123116.jpg";
import Footer from "../../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Booking";
import FAQ from "../FAQ";
// import 'react-tabs/style/react-tabs.css';
// page-id = 6

export default function BikeTrekKayak() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>Kerala Trailblaze: Biking, Hiking, Kayaking Odyssey</h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  The adventure of a lifetime with our epic motorcycling,
                  trekking, and kayaking tour, a thrilling odyssey that takes
                  you on a mesmerizing circuit from the coastal gem of Kochi to
                  the tranquil backwaters of Alleppey. As you rev up your
                  motorcycle in Kochi, the historic echoes of this spice-scented
                  city are your starting point. The journey unfolds with a ride
                  through Munnar, where emerald tea plantations and misty
                  mountains greet you at every turn. From there, the path leads
                  to Meesapulimala, a pristine paradise nestled amidst the
                  Western Ghats, where trekking trails unveil breathtaking
                  vistas and nature's secrets.
                </p>
                <p>
                  As you conquer the trails, the adventure shifts gears to
                  Thekkady, a sanctuary where the untamed beauty of Periyar
                  National Park awaits. But the excitement doesn't stop there.
                  In Alleppey, a tranquil kayak journey through the shimmering
                  backwaters allows you to savor the serenity of this coastal
                  wonderland. Finally, your exhilarating journey comes full
                  circle in Kochi, leaving you with not only a lifetime of
                  memories but also a profound appreciation for Kerala's diverse
                  landscapes, cultures, and the spirit of exploration. This tour
                  isn't just an adventure; it's a transformative experience
                  through the kaleidoscope of Kerala's natural and cultural
                  riches.
                </p>
                <p>
                  So, rev your engines, lace up your trekking boots, and paddle
                  your way through the enchanting waters, for the Motorcycling,
                  Trekking, and Kayaking Tour promises not only
                  adrenaline-pumping moments but also a deep connection with the
                  soul-stirring beauty of Southern India. It's a journey where
                  every twist of the road and ripple of water tells a story, and
                  every breathtaking vista is a chapter in your epic adventure.
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Travel Sketch</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Property</th>
                      <th>Meal Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Day 1</td>
                      <td>Fort Kochi</td>
                      <td>Fort House</td>
                      <td> -/-/D</td>
                    </tr>
                    <tr>
                      <td>Day 2</td>
                      <td>Munnar</td>
                      <td>Tea County Resort</td>
                      <td>B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 3</td>
                      <td>Rhodo Valley</td>
                      <td>Rhodo Mansion</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 4</td>
                      <td>Munnar</td>
                      <td>Tea County Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 5</td>
                      <td>Periyar</td>
                      <td>Abad Green Forest</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 6</td>
                      <td>Vagamon</td>
                      <td>Winter Vale</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 7</td>
                      <td>Alleppey</td>
                      <td>Citrus Alleppey</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 8</td>
                      <td>Nedumudy</td>
                      <td>Emerald Isle</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 9</td>
                      <td>Muhamma</td>
                      <td>Lake Canopy</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 10</td>
                      <td>Mararikulam</td>
                      <td>Turtle Beach Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 11</td>
                      <td>Depart</td>
                      <td> </td>
                      <td> B/-/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>Tea plantations at Munnar & Vagamon</li>
                  <li>Hike to Meesapulima Peak – 2640 m</li>
                  <li>Trek through Mathikettan Shola National Park</li>
                  <li>Forest walk at Periyar National Park</li>
                  <li>Ride along with the Cardamom Hills Reserve in Kerala</li>
                  <li>Exquisite healthy and local Kerala food all along</li>
                  <li>Houseboat cruise at Alleppey</li>
                  <li>Exploring backwaters on kayaks</li>
                  <li>
                    Stay at the world’s top five Hammock Beaches by National
                    Geography
                  </li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>Day 1: Fort Kochi</h6>
                  <p>
                    A one hour transfer from the airport takes you to Fort
                    Cochin. The rest of your time is to rest – beat jet lag and
                    get set for your adventure in the next few days. Cochin is
                    the cosmopolitan centre of Kerala. Your hotel is of the
                    boutique kind and one among the very few in the colonial and
                    heritage town of Fort Kochi. Some of the best spots in Fort
                    Kochi and Mattancherry (the more historical hub) include the
                    Dutch palace, the Jew Synagogue, St Francis’ Church, Dutch
                    Cemetery, Chinese Fishing nets, Santa Cruz Cathedral and so
                    on. The old world charm infused with the numerous spice
                    markets, antique shops and art galleries will amaze you.
                  </p>
                </li>
                <li>
                  <h6>Day 2: Munnar</h6>
                  <p>
                    With the sun's first golden rays painting the morning sky,
                    we kick-start our day with a hearty breakfast, our
                    anticipation as fresh as the daybreak. Our journey unfurls
                    like an unwritten adventure novel, beckoning us toward the
                    lush embrace of Munnar. Leaving behind the hustle and bustle
                    of Ernakulam city, we navigate our way eastward, weaving
                    through the Mamalakandam forest, a place whispered about in
                    local tales.Here, amidst the serene hills, a landscape of
                    boundless beauty unveils itself - a tapestry of vibrant
                    greens and rolling hills, adorned with tea plantations that
                    stretch as far as the eye can see. En route, our senses are
                    awakened by the sights and scents of this incredible region.
                    We pass rubber estates, where sap drips like tears from
                    ancient trees, pepper vines that twist and turn like secrets
                    waiting to be discovered, and cardamom plantations, their
                    fragrant pods hiding stories of spice trade.
                  </p>
                  <p>
                    But it's the tea plantations that truly steal the show.
                    Munnar, cradled within these dramatic mountain landscapes,
                    boasts some of the world's highest tea estates. Here, the
                    British once sought refuge from the heat, making it their
                    beloved summer retreat. As we approach Munnar, the air grows
                    crisper, the fragrance of tea leaves more intoxicating, and
                    the promise of adventure beckons around every curve in the
                    road.
                  </p>
                </li>
                <li>
                  <h6>Day 3: Meesapulima Trek Rhodo Valley</h6>
                  <p>
                    Under the soft glow of dawn, our day begins at the inn,
                    fueled by a hearty breakfast that matches our excitement.
                    Guided by the sun, we venture toward the enigmatic Silent
                    Valley, the threshold to our adventure in the heart of
                    nature. Here, we meet Meesapulimala, the whispered secret
                    rising to 2640 meters, the second-highest peak in the
                    Western Ghats.
                  </p>
                  <p>
                    Our journey unfolds gradually, from 1500 meters to over 2600
                    meters, leading us through shola woodlands and vast
                    grasslands, signature landscapes of the Western Ghats. In
                    this wilderness, diverse wildlife thrives, from majestic
                    Elephants to elusive Sambar deer and the regal Nilgiri tahr.
                    Atop Meesapulimala, we touch the sky, savoring a panoramic
                    view below. As the day ends in the tranquil Rhodo Valley, we
                    rest beneath a starlit canopy, knowing each step has etched
                    another chapter in the epic of Meesapulimala.
                  </p>
                </li>
                <li>
                  <h6>Day 4: Yellapatti Munnar</h6>
                  <p>
                    Awaken to the enchanting spectacle of dawn in Rhodo Valley,
                    where the sun's first kiss paints the sky in hues of gold,
                    and the mountain breeze carries secrets from the heights.
                    Nestled amidst the embrace of the Shola forest, this valley
                    is a haven that promises an ethereal stay, where the
                    whispers of nature serenade you to sleep. As the day
                    unfurls, our adventure continues with a post-breakfast trek
                    that begins at the mansion, leading us deeper into the heart
                    of the woods, toward the mesmerizing Yellapatti. The trail
                    winds through the Shola Forest, a realm where ancient trees
                    guard timeless tales, and across vast grasslands that unveil
                    countless panoramas of the Western Ghats' grandeur.
                  </p>
                  <p>
                    In this journey, every step is a moment of wonder, a chance
                    encounter with nature's masterpieces. As we tread further
                    into the wilderness, the Western Ghats reveal their secrets,
                    captivating our senses with astonishing vistas that etch
                    themselves into our memory. The trek from Rhodo Valley to
                    Yellapatti is not merely a path; it's an odyssey, a living
                    story written by the untamed beauty of the Western Ghats,
                    inviting us to become part of its narrative.
                  </p>
                </li>
                <li>
                  <h6>Day 5: Periyar Tiger Reserve</h6>
                  <p>
                    Our journey continues along the winding mountain roads, a
                    ribbon of adventure unfurling before us, leading us toward
                    the renowned Periyar Tiger Reserve. Nestled within the
                    embrace of nature, Periyar stands as a testament to
                    exemplary wildlife conservation in India, where Asiatic
                    elephants, majestic Indian Bison, and elusive Tigers roam
                    freely. As we ride on this exhilarating day, the terrain
                    undulates beneath us, challenging our spirit with a couple
                    of invigorating climbs that demand determination and grit.
                  </p>
                  <p>
                    Along the way, the landscape evolves like a living painting,
                    where dense forests whisper tales of ancient mysteries, and
                    the air is infused with the fragrance of cardamom estates
                    and lush spice gardens. And then, just as the journey is
                    measured in miles, it's equally measured in moments, each
                    one bringing us closer to the heart of Periyar. This leg of
                    our ride is more than an expedition; it's an immersion into
                    the untamed wonders of the wild, an experience where every
                    twist in the road is an invitation to discover the
                    extraordinary.
                  </p>
                </li>
                <li>
                  <h6>Day 6: Vagamon</h6>
                  <p>
                    Amidst a canvas painted in the warm hues of an Indian
                    sunset, we embark on an extraordinary journey from Periyar
                    to Vagamon, a day that promises to be nothing short of
                    breathtaking. As our wheels roll onward, we approach
                    Vagamon, a town that seems to have been plucked from the
                    realms of dreams. Here, scenic valleys cradle their secrets
                    in lush meadows, pine forests enchant with their quiet
                    wisdom, and mists and fogs weave a mystic veil over the
                    landscape.
                  </p>
                  <p>
                    The route we traverse is nothing less than a mountain
                    biker's paradise, where the landscape unfolds like poetry.
                    We pass through quaint spice-growing hamlets that pepper the
                    countryside, their aromas lingering in the air like fragrant
                    verses. The undulating roads wind their way through emerald
                    tea estates, where the symphony of rustling leaves serenades
                    our journey, and the embrace of the forest invites us to
                    explore its hidden treasures. Our destination, a haven
                    nestled amidst these very tea estates, promises respite and
                    serenity, a quiet retreat where we can rejuvenate after a
                    day of exhilarating riding. Vagamon is not just a place;
                    it's an invitation to lose ourselves in the embrace of
                    nature's magnificence, where every turn of the pedal is a
                    stroke on the canvas of a memorable adventure.
                  </p>
                </li>
                <li>
                  <h6>Day 7: Alleppey</h6>
                  <p>
                    As our journey nears its graceful descent, we bid farewell
                    to the serene heights and begin our exhilarating downhill
                    ride, winding through the verdant tapestry of rubber estates
                    that adorn the landscape. The path unfolds like a gentle
                    invitation, leading us onward to the charming town of
                    Erattupetta. Here, a brief pause for tea rejuvenates both
                    body and spirit, preparing us for the final leg of our
                    biking odyssey.
                  </p>
                  <p>
                    With renewed energy, we pedal our way toward the enchanting
                    backwater haven of Alleppey, a destination that promises a
                    mesmerizing blend of experiences. Along this scenic route,
                    we traverse a tapestry of sights and sounds—paddy fields,
                    where ducks serenely graze; bustling floating markets, where
                    life unfolds upon the water; local toddy shops, where
                    stories are exchanged over a traditional brew; and the
                    sacred precincts of temples and churches that speak of a
                    rich cultural tapestry. As night descends, we find solace in
                    the comforts of a backwater hotel, our home amidst this
                    aquatic wonderland. Our journey from the heights to the
                    plains, a symphony of contrasts and discoveries, is a
                    testament to the diverse beauty of Kerala's landscapes and
                    the adventures that await at every turn of the pedal.
                  </p>
                </li>
                <li>
                  <h6>Day 8: Nedumudy</h6>
                  <p>
                    Today, our adventure takes on a new rhythm as we embark on a
                    kayaking journey through the labyrinthine backwater
                    villages. With each paddle stroke, we glide through the
                    gentle waters, meandering through hidden inlets that reveal
                    the timeless charm of rustic backwaters. Here, tranquility
                    reigns supreme, and the serenity of this rural oasis
                    envelops us like a warm embrace.As we navigate the
                    waterways, we become part of the rich tapestry of local
                    life. The close interactions with the villagers who call
                    this place home offer us a glimpse into their daily routines
                    and traditions, a captivating window into a way of life that
                    feels worlds apart from the hustle and bustle of modernity.
                  </p>
                  <p>
                    As the sun gently descends toward the horizon, we find
                    ourselves at a cozy island retreat nestled amidst the
                    enchanting backwaters. It's here that we'll rest for the
                    night, a peaceful haven amidst the natural beauty that
                    surrounds us. This kayaking adventure is not just a journey;
                    it's an immersion into the heart of Kerala's backwater
                    culture, where every ripple on the water tells a story, and
                    every encounter with the locals becomes a cherished memory.
                  </p>
                </li>
                <li>
                  <h6>Day 9: Muhamma</h6>
                  <p>
                    As dawn graces us with its gentle touch, our day begins with
                    a traditional breakfast, a delightful spread served in the
                    comforting embrace of an ancestral home nestled on the
                    backwaters. The moment is a nod to the heritage and culture
                    that infuses every corner of this region, a reminder of the
                    timeless allure that draws travelers from all corners of the
                    globe. With the day stretching ahead, we continue our
                    leisurely journey along the tranquil waters, the very
                    essence of why this destination remains a magnet for
                    travelers year after year. The rhythm of life here unfolds
                    like a soothing lullaby, and each passing scene whispers
                    stories of a land intertwined with nature's wonders.
                  </p>
                  <p>
                    As the afternoon sun bathes the world in its warm glow, we
                    gather for a delicious lunch that embodies the flavors of
                    Kerala. Satiated and content, we embark on the final leg of
                    our adventure, where we're led to a beautiful resort, a
                    haven where relaxation takes center stage. Here, amidst the
                    serene surroundings, we'll find the perfect space to unwind
                    and reflect upon the memories of our journey through the
                    enchanting backwaters.
                  </p>
                </li>
                <li>
                  <h6>Day 10: Marari Beach</h6>
                  <p>
                    Today, our journey takes a different turn as we set our
                    course for Mararikulam, a charming fishing village nestled
                    along the pristine shores of the sea. This coastal haven has
                    garnered international acclaim, earning its place among the
                    top 5 Hammock beaches in the world, as recognized by
                    National Geographic.
                  </p>
                  <p>
                    As we arrive at this idyllic destination, we're greeted by
                    the gentle whispers of the sea and the promise of relaxation
                    that awaits us. Here, we check into a splendid beachfront
                    lodge, the perfect culmination to our two-week adventure
                    through the enchanting landscapes of Kerala. The tranquil
                    waves lapping at the shore, the rustling palm trees, and the
                    warm embrace of the sun all beckon us to unwind and savor
                    the moments of tranquility that this coastal retreat offers.
                    It's a fitting conclusion to our journey, a place where we
                    can reflect on the myriad experiences and memories that have
                    woven the tapestry of our Kerala adventure.
                  </p>
                </li>
                <li>
                  <h6>Day 11: Departure</h6>
                  <p>
                    After breakfast check out from hotel and transfer to Airport
                    for onward journey. End of services with fun filled memories
                    of your trip with The Tour Pilot.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-3.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-4.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-5.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/BikeTrekKayak/Gallery/Untitled-6.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:100;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
        margin-top:5px;
        font-weight:100;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        margin-top:20px;
      }
      
    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      @media(max-width:576px){
        .react-tabs__tab-list{
          flex-direction: column;
          height:209px; 
          width: 86%;
        }
        .AboutTour {
          padding-top: 40px;
          padding-bottom: 40px;
          width: 92%;
      }
      .AboutTour-content p{
        font-size: 12px;
      }
      .Highlights{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 92%;
      }
      .TourPage-Overview {
        font-size: 10px;
      }
      .Highlights ul {
        font-size: 13px;
      }
      .AboutTour-ul {
        height: 27vh;
        width: 76%;
      }
      .AboutTour-ul ul{
        padding-left: 10px;
      }
      .TourPage-Title h1{
        font-size: 1.3em;
      }
      .Itinerary {
        width:100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .wrapper {
        padding-right: 10px;
        padding-left: 2px;
      }
      .Itinerary-content ul{
        padding-top: 5px;
        padding-left:30px;
      }
      .Itinerary-content h6{
        font-size: 20px;
    }
    .Itinerary-content p{
      font-size: 15px;
    }
    .gallery img{
      width:300px;
      height:180px;
    }
    }
`;
