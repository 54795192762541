import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/FortKochiDayCycling/Banner/Chinese_net.jpg";
import Footer from "../../Home/Footer";
import Booking from "../Booking";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import FAQ from "../FAQ";
// import 'react-tabs/style/react-tabs.css';
// page-id = 1

export default function FortKochiDayCycling() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>
            Pedaling through History: Fort Kochi and Mattancherry Day Cycling
            Tour
          </h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  Experience the vibrant history and culture of Fort Kochi and
                  Mattancherry in a unique and eco-friendly way with our day
                  cycling tour. Explore the charming streets and immerse
                  yourself in the rich heritage of these captivating
                  neighborhoods on the southwest coast of India. Discover the
                  hidden gems, vibrant markets, and cultural diversity of Fort
                  Kochi and Mattancherry on our day cycling tour. Whether you're
                  a history enthusiast, a nature lover, or simply seeking an
                  authentic experience, this tour promises an unforgettable
                  journey through the heart of Kerala's coastal heritage. Join
                  us for a memorable exploration of Fort Kochi and Mattancherry
                  on two wheels!
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>
                    Historic Fort Kochi: Begin your journey in the historic Fort
                    Kochi area, known for its colonial architecture, ancient
                    churches, and picturesque waterfront. Pedal past iconic
                    landmarks, including the Chinese fishing nets, St. Francis
                    Church, and the vibrant Jew Town.
                  </li>
                  <li>
                    Spice Markets of Mattancherry: Cycle through the aromatic
                    spice markets of Mattancherry, where the air is filled with
                    the fragrance of spices such as cinnamon, cloves, and
                    cardamom. Witness the bustling trade that has thrived here
                    for centuries.
                  </li>
                  <li>
                    Dutch Palace (Mattancherry Palace): Explore the Dutch
                    Palace, also known as Mattancherry Palace, renowned for its
                    stunning Kerala murals that depict scenes from Hindu epics.
                    Dive into the history of this architectural gem.
                  </li>
                  <li>
                    Paradesi Synagogue: Visit the Paradesi Synagogue, the oldest
                    active synagogue in the Commonwealth, located in the heart
                    of Mattancherry. Admire its distinctive blue-and-white tiles
                    and learn about the Jewish heritage of the area.
                  </li>
                  <li>
                    Local Encounters: Along the way, interact with local
                    artisans, observe traditional craft-making, and savor the
                    flavors of Kerala cuisine at local eateries. Engage with the
                    diverse cultures that have shaped these neighborhoods.
                  </li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  Introduction (6:00 AM - 6:15 AM) Meeting Point: Gather at the
                  designated meeting point in Fort Kochi, where you'll be
                  introduced to your expert guide for the day. Orientation: Get
                  familiar with your bicycle, safety guidelines, and the day's
                  plan.
                </li>
                <li>
                  Fort Kochi Exploration (6:15 AM - 7:15 AM) St. Francis Church:
                  Begin your journey with a visit to St. Francis Church, the
                  oldest European church in India. Learn about its historical
                  significance and architectural features. Chinese Fishing Nets:
                  Marvel at the iconic Chinese fishing nets along the
                  waterfront. Discover the traditional fishing methods passed
                  down through generations.
                </li>
                <li>
                  Jew Town and Spice Markets (7:15 AM - 8:30 AM) Jew Town: Cycle
                  to the vibrant Jew Town area, known for its antique shops and
                  the historic Jewish synagogue. Explore the unique blend of
                  cultures and stories. Spice Markets: Immerse yourself in the
                  aromatic spice markets of Mattancherry. Witness the bustling
                  trade and learn about the spices that have shaped the region's
                  history.
                </li>
                <li>
                  Dutch Palace and Synagogue (8:30 AM - 9:30 AM) Dutch Palace
                  (Mattancherry Palace): Explore the Dutch Palace, known for its
                  exquisite Kerala murals that depict scenes from Hindu epics.
                  Dive into the history and artistry of the palace. Paradesi
                  Synagogue: Visit the Paradesi Synagogue, a symbol of Jewish
                  heritage in the region. Admire the architecture and learn
                  about its centuries-old legacy.
                </li>
                <li>
                  Return to Fort Kochi (9:30 AM - 10:00 AM) Return Journey:
                  Cycle back to Fort Kochi, taking a different route to explore
                  more of the city's hidden gems and local life. Feedback and
                  Farewell: Return your bicycles and gather for a group
                  discussion. Share your experiences, ask questions, and provide
                  feedback.
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/aby-zachariah-lgtaXW_z81I-unsplash.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/chinese_net_man.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/Untitled-3.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/FortKochiDayCycling/Gallery/miguel-baixauli-8xET_ZpMqIk-unsplash.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:bold;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    .AboutTour-ul li{
      margin-top:5px;
      font-weight:100;
  }
  @media(max-width:576px){
    .react-tabs__tab-list{
      flex-direction: column;
      height:209px; 
      width: 86%;
    }
    .AboutTour {
      padding-top: 40px;
      padding-bottom: 40px;
      width: 92%;
  }
  .AboutTour-content p{
    font-size: 12px;
  }
  .Highlights{
    padding-top: 30px;
    padding-bottom: 30px;
    width: 92%;
  }
  .TourPage-Overview {
    font-size: 10px;
  }
  .Highlights ul {
    font-size: 13px;
  }
  .AboutTour-ul {
    height: 27vh;
    width: 76%;
  }
  .AboutTour-ul ul{
    padding-left: 10px;
  }
  .TourPage-Title h1{
    font-size: 1.3em;
  }
  .Itinerary {
    width:100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .wrapper {
    padding-right: 10px;
    padding-left: 2px;
  }
  .Itinerary-content ul{
    padding-top: 5px;
    padding-left:30px;
  }
  .Itinerary-content h6{
    font-size: 20px;
}
.Itinerary-content p{
  font-size: 15px;
}
.gallery img{
  width:300px;
  height:180px;
}
}
}
`;
