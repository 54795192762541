import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import Footer from "../Home/Footer";
import ContactUsBanner from "../Images/ContactUs/ContactUsBanner.jpg";
import FormPage from "../Home/FormPage";

export default function ContactUs() {
  return (
    <>
      <style>{css}</style>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <Navbar />
      <div
        className="ContactUs-banner-page"
        style={{ backgroundImage: `url(https://thetourpilot.com/resources/Images/AboutUS/photo_2023-11-19_01-57-19.jpg)` }}
      ></div>
      <div className="ContactUs-content">
        <div className="ContactUs-content-title">
          <h3 className="ContactUs-content-title-h3">Contact Us</h3>
          <h6 className="ContactUs-content-title-h6">All geared up, Let's Talk</h6>
        </div>
        <FormPage />
        <ul className="contactus-ul">
          <div className="contactus-li">
            <i class="fa-solid fa-map-location-dot"></i>
            <h3 className="contactus-li-content">The Tour Pilot</h3>
            <h6 className="contactus-li-content">1/267 Lilly Street</h6>
            <h6 className="contactus-li-content">Fort Kochi</h6>
          </div>
          <div className="contactus-li">
            <i class="fa-solid fa-mobile-screen-button"></i>
            <h3 className="contactus-li-content">+91 8848197010</h3>
          </div>
          <div className="contactus-li">
            <i class="fa-solid fa-envelope"></i>
            <h4 className="contactus-li-content">info@thetourpilot.com</h4>
            <h4 className="contactus-li-content">thetourpilotcommunity@gmail.com</h4>
          </div>
        </ul>
      </div>
      <Footer />
    </>
  );
}
const css = `
.ContactUs-banner-page{
    height: 75vh;
    width:100%;
    background-color:#344e59;
    background-position:center;
    background-size:cover;
}
.ContactUs-content{
    height:100%;
    width:100%;
    background-color:#344e59;
    color:#fff;
}
.ContactUs-content-title{
    width:80%;
    margin:auto;
    padding:10px 0;
    font-size:50px;
}
.contactus-ul{
    width:80%;
    height:100%;
    list-style:none;
    display:grid;
    grid-template-columns:auto auto auto;
    margin:auto;
    padding:20px 0;
    gap:10px;
    justify-content:space-between;
}
.contactus-li{
    border-style:solid;
    border-radius:10px;
    text-align:center;
    border-width: small;
    height:150px;
    width:300px;
}
.contactus-li i{
  font-size:30px;
  color:#fff;
  padding:20px 0;
}
.contactus-li h3{
  font-size:20px;
}
.contactus-li h6{
  font-size:15px;
}
@media (max-width:576px){
  .ContactUs-banner-page{
    height:40vh;
  }
  .ContactUs-content-title-h3{
    font-size:30px;
  }
  .ContactUs-content-title-h6{
    font-size:20px;
  }
  .contactus-ul{
    grid-template-columns:auto;
  }
  .contactus-li h3 {
    font-size: 17px;
  }
  .contactus-li{
    height: 140px;
    width: 290px;
    margin:auto;
  }
}
`;
