import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/CostToCost/Banner/Tamil_Nadu.jpg";
import Footer from "../../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Booking";
import FAQ from "../FAQ";
// import 'react-tabs/style/react-tabs.css';
// page-id = 5

export default function CostToCost() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>
            Southern India Motorcycle Adventure:East Coast to West Cost via
            Enchanting Destinations
          </h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  This tour will take you through the heart of Southern India
                  from the east coast at Chennai also known as Madras to the
                  west coast at Cochin in Kerala. This unique tour throws in a
                  mixture of the cultural and architectural highlights including
                  the Mahabalipuram Shore temples, the temples at Kumbakonam and
                  Madurai as well as the bungalows and palaces of the Chettinad
                  Chettiars at Karaikudi. We also want you to experience the
                  local fares and cuisine along the way which is as rich and
                  colourful as the temples.
                </p>
                <p>
                  As you move on from Tamil Nadu to Kerala, the topography takes
                  a complete u-turn with verdant forests, tea estates, spice
                  farms, coconut groves, rubber plantations, jungles and
                  national parks. Experience the cultural vibrancy of Southern
                  India in full spirit of the land on your riding tour in South
                  India. We love this tour for the many mystical temples,
                  innumerable smiles of the Dravidian people and stomach loads
                  of lovely food!
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Travel Sketch</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Property</th>
                      <th>Meal Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Day 1</td>
                      <td>Mahabalipuram</td>
                      <td>Radisson Blu</td>
                      <td> -/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 2</td>
                      <td>Pondicherry</td>
                      <td>Maison Perumal Cgh</td>
                      <td>B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 3</td>
                      <td> Karaikkal</td>
                      <td>The Bungalow On The Beach</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 4</td>
                      <td>Kumbakonam</td>
                      <td>Indeco Swamimalai</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 5</td>
                      <td>Tanjore</td>
                      <td>Hotel Sangam</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 6</td>
                      <td>Karaikkudy</td>
                      <td>Visalam Cgh</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 7</td>
                      <td>Madurai</td>
                      <td>Heritage Madurai</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 8</td>
                      <td>Kodaikkanal</td>
                      <td>The Carlton Kodai</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 9</td>
                      <td>Kodaikkanal</td>
                      <td>The Carlton Kodai</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 10</td>
                      <td>Munnar</td>
                      <td>Kaivalyam Retreat</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 11</td>
                      <td>Alleppey</td>
                      <td>Deluxe Houseboat</td>
                      <td> B/D/L</td>
                    </tr>
                    <tr>
                      <td>Day 12</td>
                      <td>Fort Cochin</td>
                      <td>Light House</td>
                      <td> B/L/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>Explore the shore temples at Mahabalipuram</li>
                  <li>Riding along the coastal stretch to Pondicherry</li>
                  <li>Experience the remnants of colonial French rule</li>
                  <li>
                    Experience the art, culture and temples of South India
                  </li>
                  <li>
                    Witness the Dravidian culture and lifestyle of South Indian
                    People
                  </li>
                  <li>Visit the famous Madurai Meenakshi Amman Temple</li>
                  <li>
                    Ride along the countryside bordered with coconut groves and
                    sugarcane fields
                  </li>
                  <li>
                    Take in the fresh mountain air and relax in the quietness of
                    the hills
                  </li>
                  <li>Explore the heritage city of Fort Cochin</li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>Day 1: Mahabalipuram</h6>
                  <p>
                    On arrival at Annadurai International Airport @ Chennai meet
                    and be greeted by your tour leader & driver. A quick 2 hours
                    transfer takes you to a hotel close to the 7th century
                    Mahabalipuram Shore temples. Here, the architectural
                    highlights of the temples portray events from the scared
                    Hindu book of Mahabharata. These temples were built during
                    the reigns of Narasimhavarman and his successor
                    Rajasimhavarman of the Pallava dynasty. Walk around
                    Mahabalipuram to experience your first temple in South India
                  </p>
                </li>
                <li>
                  <h6>Day 2: Pondicherry</h6>
                  <p>
                    We should leave early today to ride along the coastal
                    stretch to Pondicherry. The ride today is not exciting but
                    it helps to get used to the warm weather. At Pondy you can
                    not only experience the remnants of colonial French rule but
                    also explore the Tamil culture. Pondy has a native, Tamil
                    Quarter and a French Quarter. You will be booked into a
                    restored heritage bungalow in the French Quarter for the
                    night. Walking around both the quarters gives you a
                    wonderful start to understanding a people who have accepted
                    and taken in cultures from all over the world. Enjoy
                    mouth-watering dishes and laze around with beer at Puducheri
                    or Pondicherry.
                  </p>
                </li>
                <li>
                  <h6>Day 3: Karaikal</h6>
                  <p>
                    You will ride along vast stretches of paddy-fields through
                    the delta of the great Cauvery River passing many villages
                    on the way. Locals thrive on rice cultivation and animal
                    husbandry for a living. The area is considered the granary
                    of South India for its agricultural prowess in cultivating
                    large quantities of paddy. Your destination for today is
                    Karaikal which is a beach destination but one that is not
                    touristy. Relax and rewind in the hotel’s along with local
                    fares for lunch and dinner.
                  </p>
                </li>
                <li>
                  <h6>Day 4: Tanjore</h6>
                  <p>
                    Tanjore or Thanjavur is the next stop and is a town where
                    classical art forms still thrive. A walk through this town
                    will show you the stone sculptures from the 9th and 12th
                    centuries. The Saraswati Mahal Library established in the
                    1700s has ancient manuscripts on palm leaf, and many works
                    on medicine, and rare treatises. The place is renowned for
                    the Tanjore paintings and this is where it was developed. We
                    will also visit the wonderful Swathi Mahal Palace, the Art
                    Gallery as well as the Brihadeeswara Temple.
                  </p>
                </li>
                <li>
                  <h6>Day 5: Karaikudi</h6>
                  <p>
                    Today we ride towards Chettinad, famous all over South
                    India for its flavourful Chettinad cuisine. It’s a great
                    place to go riding as and you continue witnessing the
                    Dravidian culture, its people and their practices. Men in
                    lungi and women draped in saris fill the streets as they go
                    about their daily life. Religion and culture are of utmost
                    importance to the people of Tamil Nadu. Karaikudi is
                    specially known for its traditional mansions some of which
                    have more than a 100 rooms.
                  </p>
                </li>
                <li>
                  <h6>Day 6: Madurai</h6>
                  <p>
                    Day 6 takes you to the temple city of Madurai where you will
                    visit the Madurai Meenakshi Amman Temple. Established during
                    the Sangam period, this temple has some beautiful
                    architecture of gods and goddesses. This city is also known
                    for classical carnatic music and classical bharatanatyam
                    dance. The temple forms the heart and lifeline of the
                    2,500-year-old city of Madurai and is a significant symbol
                    for the Tamil people.
                  </p>
                </li>
                <li>
                  <h6>Day 7,8: Kodaikanal</h6>
                  <p>
                    The day begins with a challenging 1200 metre uphill ride to
                    the cooler climes of the hill station town Kodaikanal. There
                    is a long climb to finish this afternoon’s riding, but we
                    are rewarded with scenic views of the plains below as we end
                    this stunning day.
                  </p>
                </li>
                <li>
                  <h6>Day 9: Munnar</h6>
                  <p>
                    Our day begins with a 5km downhill ride before we join
                    undulating road for 30-35km. We continue our long, winding
                    ride downhill away from forest and coffee plantations to
                    the arid plains at a low of 300m. After a stop for lunch we
                    head to Palani, a densely occupied Hindu pilgrim town. In
                    the evening you can visit the Temple (optional extra) where
                    a Golden Chariot Procession takes place. The temple is
                    located on a hilltop which we can reach by cable car. You
                    will have a magnificent view of surrounding villages.
                  </p>
                  <p>
                    We leave Palani and are soon on quiet roads passing through
                    small villages and a landscape of huge monoliths. We ride
                    on through Indira Gandhi and Chinoor national parks (32 km)
                    to Marayoor. In the Malayalam and Tamil languages “mara”
                    means hidden and “oor” means land. The fresh mountain air
                    and views of mist-clad hills and hamlets make this a
                    beautiful location. There are cave temples and Neolithic
                    stone coffins, sugar cane fields, waterfalls and bamboo
                    forests. It is the only place in Kerala that has a natural
                    growth of sandal wood trees. The rare Neelakurinji flower is
                    native to this region.
                  </p>
                </li>
                <li>
                  <h6>Day 10: Alleppey</h6>
                  <p>
                    Early breakfast and we’re out for a thrilling 30 kms descent
                    and through the tea and cardamom estates is another
                    attraction. After a quick break we transfer you out of town
                    to Alleppey. A luxury rice-barge moored on the Vembanad Lake
                    awaits our arrival. Festive lunch on boat, music in the
                    background, you’re next in charge steering the boat through
                    vast stretches of blue waters. Coconut palms, temples and
                    villages in the horizon, you have a book out to sketch or
                    write a poem. Amidst a million stars in the night sky, the
                    breeze silently steers you into God’s own waters
                  </p>
                </li>
                <li>
                  <h6>Day 11: Fort Cochin</h6>
                  <p>
                    This morning you can watch the sun rise from the comfort of
                    the house boat, and enjoy breakfast as we cruise towards
                    Alleppey, disembarking late morning. We begin a coastal ride
                    through shady coconut palms and sleepy fishing villages. We
                    stop for a picnic lunch and you have the opportunity to cool
                    off with a dip in the Arabian Sea. This afternoon we ride
                    through prawn farms and rice paddies to Cochin on the
                    Malabar Coast.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/IMG_20190120_114920.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/IMG_20190122_110528.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/Untitled-3.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/CostToCost/Gallery/Tamil_Nadu2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:100;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
        margin-top:5px;
        font-weight:100;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        margin-top:20px;
      }
      
    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      @media(max-width:576px){
        .react-tabs__tab-list{
          flex-direction: column;
          height:209px; 
          width: 86%;
        }
        .AboutTour {
          padding-top: 40px;
          padding-bottom: 40px;
          width: 92%;
      }
      .AboutTour-content p{
        font-size: 12px;
      }
      .Highlights{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 92%;
      }
      .TourPage-Overview {
        font-size: 10px;
      }
      .Highlights ul {
        font-size: 13px;
      }
      .AboutTour-ul {
        height: 27vh;
        width: 76%;
      }
      .AboutTour-ul ul{
        padding-left: 10px;
      }
      .TourPage-Title h1{
        font-size: 1.3em;
      }
      .Itinerary {
        width:100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .wrapper {
        padding-right: 10px;
        padding-left: 2px;
      }
      .Itinerary-content ul{
        padding-top: 5px;
        padding-left:30px;
      }
      .Itinerary-content h6{
        font-size: 20px;
    }
    .Itinerary-content p{
      font-size: 15px;
    }
    .gallery img{
      width:300px;
      height:180px;
    }
    }
    }
`;
