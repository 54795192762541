import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar/Navbar";
import Footer from "../Home/Footer";

export default function AboutUs() {
  return (
    <>
      <style>{css}</style>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <Navbar />
      <div
        className="about-banner-page"
        style={{
          backgroundImage: `url(https://thetourpilot.com/resources/Images/AboutUS/banner.jpg)`,
        }}
      ></div>
      <div className="about-content-page">
        <h3>About Us</h3>
      </div>
      <div className="about-content">
        <div className="content-description">
          <h3 className="description-title"> ‘ The Tour Pilot ’</h3>
          <p className="description-p">
            “Travel is the main thing you purchase that makes you more
            extravagant”. We, at The Tour Pilot’, swear by this and put stock in
            satisfying travel dreams that make you perpetually rich constantly.
          </p>
          <p className="description-p">
            We have been moving excellent encounters for a considerable length
            of time through our cutting-edge planned occasion bundles and other
            fundamental travel administrations. We rouse our clients to carry on
            with a rich life, brimming with extraordinary travel encounters.
          </p>
          <p className="description-p">
            Through our exceptionally curated occasion bundles, we need to take
            you on an adventure where you personally enjoy the stunning
            magnificence of America and far-off terrains. We need you to observe
            sensational scenes that are a long way past your creative ability.
            The powerful inclination of people to travel more nowadays is
            something that keeps us inspired to satisfy our vacation
            necessities. Our vision to give you a consistent occasion encounter
            makes us one of the main visit administrators in the regularly
            extending travel industry.
          </p>
          <p className="description-p">
            To guarantee that you have a satisfying occasion and healthy
            encounters, all our vacation administrations are available to your
            no matter what.
          </p>
        </div>
        <ul className="about-ul">
          <li className="about-li">
            <h4 className="about-li-heading">Zenith Experience</h4>
            <p className="about-li-p">
              On our journeys, you'll encounter a blend of renowned landmarks
              and concealed treasures that elude others or remain undiscovered.
              As explorers, our aim is not just to observe a country but to
              immerse ourselves in its essence. We'll bring you intimately
              closer to the heart of the nation, its vibrant culture, and its
              people, often through unscripted, spontaneous, and utterly unique
              encounters. You'll return home having witnessed and embraced the
              country in a manner that sets your experience apart from that of
              other travelers.
            </p>
          </li>
          <li className="about-li">
            <img
              src={
                "https://thetourpilot.com/resources/Images/AboutUS/photo_2023-11-19_01-54-54.jpg"
              }
              alt=""
              className="about-li-img"
            />
          </li>
          <li className="about-li">
            <img
              src={
                "https://thetourpilot.com/resources/Images/AboutUS/photo_2023-11-19_01-52-54.jpg"
              }
              alt=""
              className="about-li-img"
            />
          </li>
          <li className="about-li">
            <h4 className="about-li-heading">Expert tour leaders</h4>
            <p className="about-li-p">
              When you secure a spot on one of our curated small group tours,
              your dedicated tour leader becomes your gateway to a realm of
              extraordinary experiences. Armed with a wealth of insider
              knowledge and deep-rooted local connections, they transform your
              journey into a tapestry of unforeseen possibilities. Picture
              yourself welcomed to witness the dawn from a historic lighthouse
              or being warmly included in the vibrant festivities of a Diwali
              celebration with a local family. Your tour leader serves as the
              orchestrator, ensuring the seamless orchestration of every detail,
              leaving you free to savor the essence of your adventure. Trained
              meticulously by our seasoned team, our tour leaders epitomize
              unwavering excellence, ensuring your expedition is nothing short
              of extraordinary.
            </p>
          </li>
          <li className="about-li">
            <h4 className="about-li-heading">Responsible at heart</h4>
            <p className="about-li-p">
              Distinguished by our eco-conscious ethos and adaptable reservation
              policies, we stand out in the travel landscape. Our unwavering
              commitment is directed towards diminishing our footprint on the
              planet, actively contributing to a sustainable future. Each of our
              journeys is now accompanied by a carbon label, a testament to our
              dedication to environmental responsibility. Beyond ecological
              concerns, we tirelessly strive to create a positive influence on
              the communities we explore. In times of uncertainty, our steadfast
              approach to flexible booking conditions has garnered praise,
              particularly for our principled actions during the pandemic. This
              means you can persist in making reservations with the utmost
              confidence, knowing that we prioritize your peace of mind and
              well-being.
            </p>
          </li>
          <li className="about-li">
            <img
              src={
                "https://thetourpilot.com/resources/Images/AboutUS/photo_2023-11-19_01-56-50.jpg"
              }
              alt=""
              className="about-li-img"
            />
          </li>
          <li className="about-li">
            <img
              src={
                "https://thetourpilot.com/resources/Images/AboutUS/photo_2023-11-19_01-58-22.jpg"
              }
              alt=""
              className="about-li-img"
            />
          </li>
          <li className="about-li">
            <h4 className="about-li-heading">Responsible Tourism</h4>
            <p className="about-li-p">
              We are dedicated to providing an immersive experience where
              conservation and local cultures take center stage. Our approach
              ensures that your encounters with wildlife, communities, and
              traditions are both enriching and environmentally considerate.
              Working collaboratively and respectfully with local communities,
              we strive for a positive impact on their livelihoods, fostering a
              sense of pride in sharing knowledge and culture. Our partnerships
              extend to organizations, business associates, and individuals who
              align with our high service standards and shared conservation
              goals. Whenever possible, we prioritize collaborations with local
              entities, small enterprises, and environmentally conscious
              initiatives. As pioneers in responsible tourism, our genuine
              commitment to conservation and unwavering respect for the dignity
              of the communities we engage with set us apart as trailblazers in
              the field.
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

const css = `
.about-banner-page{
    height:75vh;
    width:100%;
    background-size:cover;
    background-position:bottom;
}
.about-content-page{
    background-color:#344e59;
}
.about-content-page h3{
    color: #fff;
    width:80%;
    font-size:50px;
    text-align:left;
    margin:0 auto;
    padding:10px 0;
}
.about-content{
    height:100%;
    width:100%;
    background-color:#344e59;   
}
.about-ul{
    list-style:none;
    width:80%;
    display:grid;
    grid-template-columns:auto auto;
    color:#fff;
    margin:auto;
    padding:40px 0;
    
}
.about-li{
    width: 600px;
    height: 350px;
}
.about-li-heading{
    font-size:30px;
    padding:12px 25px;
}
.about-li-p{
    font-size:15px;
    padding:12px 25px;
}
.about-li-img{
    width: 611px;
    height: 350px;
    object-fit: scale-down;
}
.content-description{
  width:70%;
  margin:auto;
  color:#fff;
  padding:20px 0; 
  background-color:#232f051f;
}
.description-title{
  font-size:23px;
  margin:0 3%;
}
.description-p{
  font-size:18px;
  padding-top:10px;
  margin:0 3%;
}

@media (max-width:576px){
  .about-banner-page{
    height:40vh;
  }
  .about-content-page h3{
    font-size:30px;
  }
  .description-p{
    font-size:14px;
  }
  .about-ul{
    grid-template-columns: auto;
    width:100%;
  }
  .about-li {
    width: 100%;
    height: 100%;
    margin:auto
}
.about-li-img {
  width: 350px;
  height:350px;
  margin: auto;
  display: flex;
}
.about-li-heading{
  font-size: 18px;
  text-align:center;
}
.about-li-p {
  font-size: 13px;
  text-align:center;
}
}
`;
