import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function FormPage() {
  const [Toggle,setToggle] = useState(false)
  const form = useRef();


  const formSuccessMessage = () =>{
      setToggle(!Toggle)
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5hz58yp",
        "template_ub07fi5",
        form.current,
        "7u1HoS3e5lwOwxDQG"
      )
      .then(
        (result) => {
          console.log(result.text);
          formSuccessMessage();
        },
        (error) => {
          console.log(error.text);
        }
      );

    form.current.reset();
   
  };

  return (
    <>
      <style>{css}</style>
      <div className="form-page">
        <div className="form-page-container">
          <div className="form-page-container-left">
            <h6>
              Feeling lost in choosing your perfect tour? Let us be your
              compass! Reach out to us and embark on your dream adventure today.
              Your journey begins with a single click.
            </h6>
          </div>
          <form
            className="form-page-container-right"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="form-page-form">
              <div className="form-page-form-content">
                <div className="form-item">
                  <input type="text" id="name" name="name" required />
                  <label htmlFor="name">Name</label>
                </div>
                <div className="form-item">
                  <input type="email" id="email" name="email" required />
                  <label htmlFor="email">Email address</label>
                </div>
                <div className="form-item">
                  <input
                    type="date"
                    id="date"
                    required
                    name="date"
                    className="date-field"
                  />
                  <label htmlFor="date">Date</label>
                </div>
                <div className="form-item">
                  <input
                    type="text"
                    id="description"
                    required
                    style={{ height: 100}}
                    name="description"
                  />
                  <label htmlFor="description">
                    Tell us about your dream tour!
                  </label>
                </div>
                <button
                  className="form-page-form-button"
                  type="submit"
                  value="send"
                >
                  Send Enquiry
                </button>
              </div>
            </div>
          </form>
        </div>
        {Toggle && 
        <div className="formpage-modal">
          <div className="form-outer-layer">
            {/* <div className="form-model-header">
    
            </div> */}
            <div className="form-content-body"><i class="fa-sharp fa-solid fa-circle-check"></i></div>
            <span className="form-content-body">Success</span>
            <div className="form-content-body">Await Reply</div>
            <button className="font-content-button" onClick={formSuccessMessage}>Continue</button>
          </div>
        </div>}
      </div>
    </>
  );
}

const css = `
.form-content-body{
  padding:20px;
  color:#fff;
  text-align:center;
  font-size:25px;
  font-weight:bold;
}
.form-content-body i{
  font-size:80px;
}
.form-outer-layer{
  width: 300px;
  height:300px;
  background: #344e59;
  border-radius: 10px; 
  overflow:hidden;
  padding:30px;
  text-align:center;
}
.font-content-button{
  height: 35px;
  width: 120px;
  background: linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  border-radius: 6px;
}
.font-content-button:hover{
  background-position:left bottom;
color:#fff;
box-shadow: 0 0 10px 0 #000;
background-color:#2d3e45
}
    .formpage-modal{
      left: 0px;
      top: 0px;
      position: fixed;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      background: #a1a1a147;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-page{
        width:100%;
        height:85vh;
        background-color:#344e59;
    }

    .form-page-container{
        height:100%;
        width:85%;
        margin:auto;
        display:flex;
    }
    .form-page-container-left{
        height:100%;
        width:50%;
        display:flex;
        align-content:center;
        }
    .form-page-container-left h6{
        color:#fff;
        text-align:left;
        font-size:31px;
        font-weight:bold;
        height:58%;
        width:80%;
        margin:auto;
    }
    .form-page-container-right{
        height:100%;
        width:50%;
        display:flex;
        align-item:center;
        position:relative;
    }
    .form-page-form{
        height: 75%;
        width:100%;
        margin:auto;
        background:#2d3e45;
        border-radius:20px;
        display:flex;
        flex-direction:column;
        align-content: center;
        flex-wrap: wrap;
    }
    .form-page-form-content{
        height: 75%;
        width: 90%;
        margin: auto;
        align-self: center;
    }
    .form-item{
        position:relative;
        margin-bottom:15px;
        width:95%;
    }
    .form-item input{
        display:block;
        width:100%;
        height:40px;
        background:transparent;
        border: 3px solid #ccc;
        transition: all 0.3s ease;
        border-radius:10px;
        color:#fff;
        padding:0 10px;
    }
    .form-item input:focus{
        border-color:#fff;
    }
    .form-item label{
        position:absolute;
        cursor:text;
        z-index:2;
        top:13px;
        left:10px;
        font-size:14px;
        font-weight:bold;
        background:#2d3e45;
        padding:0 10px;
        color:#fff;
        transition:all 0.3s ease;
        width: 50%;
    }
    .form-item input:focus +label,
    .form-item input:valid +label{
        font-size:12px;
        top:-5px;
        color:#fff;
        font-weight:lighter;
    }
    .form-page-form-button{
        border-radius:10px;
        height: 40px;
        width: 120px;
        background: linear-gradient(to right,#344e59 50% , white 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        transition: all 0.3s ease;    
    }
    .date-field::placeholder {
        color: transparent;
      }
    

    .form-page-form-button:hover {
        background-position:left bottom;
        color:#fff;
        box-shadow: 0 0 10px 0 #000;
        background-color:#2d3e45
      }

    @media (max-width:576px){
      .form-page-container-left h6 {
        font-size: 11px;
      }
      .form-item input{
        height: 16px;
        padding:0;
        font-size: 10px;
      }
      .form-page{
        height:40vh;
      }
      .form-item{
        margin-bottom: 12px;
      }
      .form-item label{
        font-size: 8px;
        top: 4px;
      }
      .form-page-form-button{
        height: 26px;
        width: 97px;
        font-size: 10px;
      }
      .form-item input:focus +label,
    .form-item input:valid +label{
      display:none;
        font-size:12px;
        top:-5px;
        color:#fff;
        font-weight:lighter;
    }
    .form-page-form {
      height: 80%;
    }
    .form-page-container-left{
      width:35%;
    }
    .form-page-container{
      width:90%;
    }
    .form-page-container-right{
      width:80%;
    }
    .form-page-form-content{
      height: 89%;
      width: 85%;
    }
    .form-outer-layer{
      width: 250px;
      height:250px;
    }
  }

`;
