import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Home/Footer";
import { Helmet } from "react-helmet";
import TourHomeBannerBg from "../Images/TourHome/TourHome-banner/IMG_20190328_095421.jpg";
import Booking from "./Booking";
import bookingimg from "../Images/TourHome/TourHome-banner/IMG_20191210_134638.jpg";
import axios from "axios";

export default function TourHome() {
  const [width, setwidth] = useState("25%");
  const [Ishovered, setIshovered] = useState(null);
  const [tour, settour] = useState([]);

  function widthTriger() {
    setwidth("35%");
  }
  function widthback() {
    setwidth("25%");
  }

  useEffect(() => {
    axios
      .get("https://thetourpilot.com/resources/json/Tour.json")
      .then((response) => {
        settour(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  },[]);
 
  

  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
      </Helmet>
      <style>{css}</style>
      <Navbar />
      <div
        className="TourHome-banner-page"
        style={{ backgroundImage: `url(${TourHomeBannerBg})` }}
      ></div>
      <div className="TourHome-content-page">
        <h3>Popular Tours</h3>
        <div className="TourHome-content-body">
          <div
            className="TourHome-content-left"
            style={{ height: "100%", width: "100%" }}
          >
            <ul className="TourHome-content-left-ul">
              {tour.map((tour, index) => {
                return (
                  <li className="TourHome-content-left-ul-li" key={index}>
                    <div className="TourHome-content-left-ul-li-left">
                      <div className="TourHome-content-left-ul-li-left-image">
                        <img
                          src={tour.image}
                          alt="TourImage"
                        />
                      </div>
                    </div>
                    <div
                      className="TourHome-content-left-ul-li-right"
                      onMouseEnter={() => {
                        setIshovered(index);
                      }}
                      onMouseLeave={() => {
                        setIshovered(null);
                      }}
                    >
                      {Ishovered != index && (
                        <div className="TourHome-content-left-ul-li-right-forhover">
                          <div className="TourHome-content-left-ul-li-right-top">
                            <h1>{tour.title}</h1>
                            <div className="rating">
                              <i class="fa fa-star" aria-hidden=""></i>
                              <i class="fa fa-star" aria-hidden=""></i>
                              <i class="fa fa-star" aria-hidden=""></i>
                              <i class="fa fa-star" aria-hidden=""></i>
                              <i class="fa fa-star" aria-hidden=""></i>
                            </div>
                            <div
                              className="TourHome-content-left-ul-li-right-top-location"
                              style={{ display: "flex" }}
                            >
                              <img
                                src={require("../Images/TourHome/Tourhome-Content/icons8-location-pin-64.png")}
                                alt=""
                              />
                              <h6>{tour.location}</h6>
                            </div>
                            <div
                              className="TourHome-content-left-ul-li-right-top-location"
                              style={{ display: "flex" }}
                            >
                              <img
                                src={require("../Images/TourHome/Tourhome-Content/icons8-tag-50.png")}
                                alt=""
                              />
                              <h1>{tour.activities}</h1>
                            </div>
                          </div>
                          <div className="TourHome-content-left-ul-li-right-bottom1">
                            <span>Duration</span>
                            <h6>{tour.duration}</h6>
                          </div>
                          <div className="TourHome-content-left-ul-li-right-bottom2">
                            <span>Grade</span>
                            <h6>{tour.grade}</h6>
                          </div>
                          <div className="TourHome-content-left-ul-li-right-bottom3">
                            {/* <span>Price</span>
                            <h6>{tour.price}</h6> */}
                          </div>
                        </div>
                      )}
                      {Ishovered === index && (
                        <div className="TourHome-content-left-ul-li-right-onhover">
                          <div className="TourHome-content-left-ul-li-right-onhover-content">
                            <h1>{tour.title}</h1>
                            <h5>
                              {tour.duration} | Price : {tour.price}{" "}
                            </h5>
                            <p>{tour.description}</p>
                            {/* <a href={tour.link}> */}
                            {/* <button onClick={()=> handleTourClick(tour.id,tour.title)}>View Tour</button> */}
                            <button
                              onClick={() => {
                                window.open(
                                  `/ToursDetails/#${tour.id}`,
                                  "_self"
                                );
                              }}
                            >
                              View Tour
                            </button>
                            {/* </a> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="TourHome-content-right"
            style={{
              backgroundImage: `url(${bookingimg})`,
              height: "12rem",
              width: "100%",
            }}
          >
            <div className="TourHome-content-right-right">
              <div className="TourHome-content-right-right-content">
                <h2>Uniquely Customized</h2>
                <p>
                  Crafting Exceptional Holidays Tailored to Your Budget and
                  Style
                </p>
                <Booking />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const css = `

    .TourHome-banner-page{
        height: 75vh;
        width:100%;
        background-color:#344e59;
        background-position:center;
        background-size:cover;
    }
    .TourHome-content-page h3{
        color: #fff;
        width:80%;
        font-size:50px;
        text-align:left;
        margin:0 auto;
    }
    .TourHome-content-page{
        height:100%;
        width:100%;
        background-color:#344e59;
        padding-bottom:100px;
        padding-top:30px;
    }
    .TourHome-content-body{
      display:grid;
      grid-template-columns: 75% 25%;
      width:90%;
      margin:0 auto;
      gap:1%;
    }
    .TourHome-content-left-ul{
      height:100%;
      list-style:none;
      padding-left:0px;
    }
    .TourHome-content-left-ul-li{
      width:100%;
      height:300px;
      display:flex;
      background:#344e59;
      margin-top:5%;
      transition:all 0.5s ease;
    }

    .TourHome-content-left-ul-li:hover{
      background:#2d3e45;
      box-shadow:0 0 10px 0 #000;
    }
    .TourHome-content-left-ul-li-right h1{
      font-size:20px
    }
    .TourHome-content-left-ul-li-left{
      width:30%;
      height:100%;
      display:grid;
      // justify-content:center;
    }
    .TourHome-content-left-ul-li-right{
      width:70%;
      height:100%;
      transition-duration:0.5s;     
    }
    .TourHome-content-left-ul-li-left-image{
      width: 275px;
      height: 280px;
      overflow:hidden;
      background:green;
      margin:auto;
      display:flex;
      justify-content:center;
    }
    .TourHome-content-left-ul-li-left-image img{
      width: 275px;
      height: 280px;
      margin:auto;
      transition:all 0.3s ease;
      object-fit:cover;
    }
    .TourHome-content-left-ul-li-right-forhover{
      display:grid;
      grid-template-columns: auto auto auto;
      gap:89% 0%;    
      color:#fff;
      margin:2%;
    }
    .TourHome-content-left-ul-li:hover img{
      transform:scale(1.2)
    }
    .TourHome-content-left-ul-li-right-top-location img{
      height: 20px;
    width: 20px;
    }
    .TourHome-content-left-ul-li-right-top{
      
      display:grid;
      grid-row-template:auto auto auto auto;
      gap:20%;
      grid-column-start:1;
      grid-column-end:-1;
    }
    
    TourHome-content-left-ul-li-right-bottom1{
      grid-row-start:2;
      grid-column-start:1;
    }
    .TourHome-content-left-ul-li-right-bottom2{
      grid-row-start:2;
      grid-column-start:2;
    }
    .TourHome-content-left-ul-li-right-bottom3{
      grid-row-start:2;
      grid-column-start:3;
    }
    .TourHome-content-left-ul-li-right-onhover{ 
      width: 95%;
      height: 100%;
      color: #fff;
    }    
    .TourHome-content-left-ul-li-right-onhover-content{
      width:80%;
      height:90%;
      display:grid;
      grid-template-row:auto auto auto;
      margin:2%;
    }
    .TourHome-content-body button{
      height: 32px;
      width: 114px;
      background: linear-gradient(to right,#344e59 50% , white 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      border: none;
      transition: all 0.5s ease;
      border-radius: 6px;
    }
    .TourHome-content-body button:hover{
      background-position:left bottom;
  color:#fff;
  box-shadow: 0 0 10px 0 #000;
background-color:#2d3e45
    }
    .TourHome-content-left-ul-li-right-onhover-content p{
      font-size:0.9rem;
    }
    .TourHome-content-left-ul-li-right-forhover h6{
      font-size : 18px;
    }
    .TourHome-content-left-ul-li-right-forhover span{
      font-size:15px;
      font-weight:bold;
    }
    .TourHome-content-right{
      position:sticky;
      top:20%;
      margin-top: 14.5%;
      background-position:center;
      background-size:cover;
      color:#fff;
      text-align:center;
      display:flex;
      justify-content:center;
      // display:grid;
      // grid-template-columns:30% 70%;
    }
    .TourHome-content-right-left-img{
      max-width:90%;
      height:100%;
      margin:auto;
      display:flex;
      justify-content:center;
    }
    .TourHome-content-right-left img{
      object-fit:cover;
      width: 6rem;
      height: 8.5rem;
      overflow:hidden;
      margin:auto;
    }
    .TourHome-content-right-right{
      width:100%;
      height:100%;
      display:flex;
      justify-content:center;
      flex-direction:column;
      background-color: rgb(143 145 141 / 59%);;
    }
    .TourHome-content-right-right-content{
      max-width:90%;
      margin:auto;
    }
    .TourHome-content-right-right-content button{
      font-size:10px;
      color:black;
    }
   @media (max-width:576px){
    .TourHome-banner-page{
      height: 50vh;
    }
    .TourHome-content-page h3{
      font-size:25px;
    }
    .TourHome-content-body{
      display:flex;
      flex-direction:column;
    }
    .TourHome-content-left-ul-li-left-image{
      width:100% ;
      height: 200px;
    }
    .TourHome-content-left-ul-li-left-image img{
      width: 100%;
      height: 200px;
    }
    .TourHome-content-left-ul-li{
      height: 355px;
    flex-direction: column;
    }
    .TourHome-content-left-ul-li-right-top{
    gap: 7%;
  }
    .TourHome-content-left-ul-li-right h1{
      font-size:13px;
    }
    .TourHome-content-left-ul-li-left{
      width:100%;
    }
    .TourHome-content-left-ul-li-right-top .rating{
      font-size:10px;
    }
    .TourHome-content-left-ul-li-right-top-location img{
      height: 13px;
      width: 13px;
    }
    .TourHome-content-left-ul-li-right-forhover h6{
      font-size:12px;
    }
    .TourHome-content-left-ul-li-right-forhover{
      gap: 25% 24%
    }
    .TourHome-content-left-ul-li-right-onhover{
      width: 90%;
    }
    .TourHome-content-left-ul-li-right{
      width: 100%;
    }
    .TourHome-content-left-ul-li-right-onhover-content{
      width: 100%;
    }
    .TourHome-content-left-ul-li-right-onhover-content h5{
      font-size:10px;
    }
    .TourHome-content-left-ul-li-right-onhover-content p {
      font-size: 0.5rem;
  }
  .TourHome-content-body button{
    height: 22px;
    width: 100px;
  }
   }
`;
