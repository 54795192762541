import React, { useEffect, useRef, useState } from "react";

function Navbar() {
  const navref = useRef(null);
  const [navBgChange, setnavBgChange] = useState(false);
  const [navBar, setnavBar] = useState(false);

  useEffect(() => {
    function NavbarScroll() {
      if (window.scrollY > 300) {
        setnavBgChange(true);
      } else {
        setnavBgChange(false);
      }
    }

    window.addEventListener("scroll", NavbarScroll);
    return () => {
      window.removeEventListener("scroll", NavbarScroll);
    };
  }, []);

  function NavClick() {
    return;
  }

  return (
    <>
      <style>{css}</style>
      <div
        className={navBgChange ? "navSpacer navScrolled" : "navSpacer"}
        ref={navref}
      >
        <div className="navbar ">
          <div className="navleft">
            <a href="/">
              <img
                src={require("../Images/Nav/Untitled-1-02.png")}
                alt=""
                width={150}
                height={50}
              />
            </a>
          </div>
          <div className="navright">
            <ul className="navright-ul">
              <li className="navright-ul-li">
                <a href="/Tours">TOURS</a>
              </li>
              <li className="navright-ul-li">
                <a href="/Destination">DESTINATIONS</a>
              </li>
              <li className="navright-ul-li">
                <a href="/AboutUs">ABOUT US</a>
              </li>
              <li className="navright-ul-li">
                <a href="/ContactUs">CONTACT US</a>
              </li>
            </ul>
            <div className="nav_button">
              <img
                src={require("../Images/Nav/the-tour-pilot-logo-02.png")}
                alt=""
                onClick={() => {
                  setnavBar(!navBar);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {navBar && (
        <div
          className={`navBarDropDown ${navBar ? "navBarDropDown-open" : ""}`}
        >
          <ul className="navBarDropDown-ul">
            <a href="/Tours">
              <li className="navBarDropDown-ul-li">
                Tours
                <hr className="navBarDropDown-ul-li-hr" />
              </li>
            </a>
            <a href="/Destination">
              <li className="navBarDropDown-ul-li">
                Destinations
                <hr className="navBarDropDown-ul-li-hr" />
              </li>
            </a>
            <a href="/AboutUs">
              <li className="navBarDropDown-ul-li">
                About Us
                <hr className="navBarDropDown-ul-li-hr" />
              </li>
            </a>
            <a href="/ContactUs">
              <li className="navBarDropDown-ul-li">Contact Us</li>
            </a>
          </ul>
        </div>
      )}
    </>
  );
}

const css = `
  a{
    text-decoration: none;
    color:inherit;
  }
  .navSpacer{
    width: 100vw;
    background-color: #3b6779;
    z-index: 1;
    position: fixed;
    height: 80px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    transition: background-color 0.4s ease;
  }
  .navScrolled {
    width: 100vw;
    z-index: 5;
    position: fixed;
    height: 80px;
    display: flex;
    justify-content: center;
    background-color:#2d3e45;
    color: white;
}
   .navbar {
    width:80%;
    display: flex;
    justify-content: space-between;
    background-color: #7a8764;
    z-index: 1;
    position: fixed;
    background-color: transparent;
  /* margin-top: 2%; */
    /* height: 75px; */
  }
  .navleft{
    padding-top: 1%;
  }
   .navright-ul {
    display: flex;
    width:700px;
    list-style: none;
    justify-content: space-between;
    padding-top: 3%;
   }
  .navright-ul-li {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    /* padding-top: 1%; */
    
  }
  .nav_button{
    display:none;
    width: 40px;
    height: 40px;
    margin:auto;
    
    border-radius:50%;
    5px 5px 10px rgba(0, 0, 0, 0.5);
    justify-content:center;
  }
  .nav_button img{
    
  }
  .navBarDropDown{
    width: 100%;
    background-color:#2d3e45;
    z-index: 10;
    position: fixed;
    top: 5.5%;
    // border-radius: 0 0 24px 24px ;
    overflow: hidden;
    transition: height 0.3s ease;
}
.navBarDropDown.navBarDropDown-open{
  height: 25%; 
}
  
  .navBarDropDown-ul{
    list-style:none;
    padding-left:30px;
    width:100%;
 
    // margin:auto;
  }
  .navBarDropDown-ul-li{
    margin: 4.5% 0;
    font-size: 21px;
    font-weight: bold;
    color:#fff
  }

  @media (max-width:576px){
    .navright-ul{
      display:none;
    }
    .navleft img{
      width:85px;
      height:30px;
    }
    .navbar{
      width:90%;
      margin-top: 2%;
    }
    .navSpacer{
      height:50px;
      background-color:#2d3e45;
    }
    .navScrolled{
      height:50px

    }
    .navBarDropDown-ul-li-hr{
      width:100%;
    }
    .nav_button{
      display:flex;
    }
  }
  
`;

export default Navbar;
