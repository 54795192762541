import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function TourHomeGrid() {
  const [width, setwidth] = useState("25%");
  function widthTriger() {
    setwidth("35%");
  }
  function widthback() {
    setwidth("25%");
  }

  return (
    <>
      <style>{css}</style>

      <div className="TourHome-content">
        <div className="TourHomeGrid-Header">
          <h2 style={{}}>Popular Tours</h2>
          <h4 style={{}}>
            We have a unique way of meeting your adventurous expectations!
          </h4>
        </div>
        <ul className="TourHome-list-ul">
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img
                src={require("../Images/Home/Grid/IMG_20190124_110707.jpg")}
                alt=""
              />
            </div>
            <h2>Kerala Motorcycle Odyssey</h2>
            <h6>Motorcyling</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              9 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              Prepare for an unforgettable motorcycle journey through the
              picturesque landscapes and cultural treasures of Kerala, India.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T4`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-2.jpg")} alt="" />
            </div>
            <h2>Kerala Trailblaze</h2>
            <h6>Motorcyling, Kayaking, Trekking</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              9 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              Embark on an exhilarating journey through the lush landscapes and
              serene waters of Kerala, India, on this unforgettable adventure
              tour.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T6`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-5.jpg")} alt="" />
            </div>
            <h2>Southern India Motorcycle Adventure</h2>
            <h6>Motorcyling</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              9 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              The motorcycle journey of a lifetime, cruising through the
              mesmerizing landscapes and diverse cultures of the southwest coast
              of India.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T5`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-4.jpg")} alt="" />
            </div>
            <h2>Pedaling through History</h2>
            <h6>Cycling</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              1 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              Embark on an exhilarating cycling adventure that combines
              exploration, history, and the scenic beauty of Fort Kochi and
              Mattancherry.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T1`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-3.jpg")} alt="" />
            </div>
            <h2>Coastal Cruise</h2>
            <h6>Motorcyling</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              10 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              Prepare for an unforgettable motorcycle journey through the
              picturesque landscapes and cultural treasures of Kerala, India.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T3`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
          <li className="TourHome-list-li">
            <div className="TourHome-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-6.jpg")} alt="" />
            </div>
            <h2>Historical Charm Unveiled</h2>
            <h6>Hiking</h6>
            <h6>
              <i
                class="fa fa-globe"
                aria-hidden="true"
                style={{ margin: 5, fontSize: 14 }}
              ></i>
              2 Places
            </h6>
            <hr />
            <p className="TourHome-list-li-para">
              Step back in time and immerse yourself in the rich history and
              cultural heritage of Fort Kochi and Mattancherry, two captivating
              neighborhoods on the southwest coast of India.
            </p>
            <hr />
            <div className="li-button-field">
              <button
                onClick={() => {
                  window.open(`/ToursDetails/#T2`, "_self");
                }}
              >
                Explore
              </button>
            </div>
          </li>
        </ul>
        <div className="button-field">
          <a href="/Tours">
            <button>More Tours</button>
          </a>
        </div>
      </div>
    </>
  );
}

const css = `
.TourHomeGrid-Header h2{
  font-size: 50px;
  text-align: left;
  background-color: #344e59;
  color: #fff;
  padding-top: 2%;
}
.TourHomeGrid-Header h4{
 font-size : 20px;
  text-align: left;
  backgroundColor: #7a8764;
  color: #fff;
}
.TourHomeGrid-Header{
  width:80%;
  margin:0 auto;
}

.TourHome-list-li h6{
  font-size:15px;
  color: #fff;
  padding: 2px;
  padding-left: 10px;
  font-weight:lighter;
}


.li-button-field{
  display:flex;
  flex-wrap:wrap;
  width:95%;
  padding: 3% 0;
  margin:auto;
  // justify-content:center;
  // align-content:left;
}
.li-button-field button{
  height: 32px;
  width: 114px;
  background: linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: all 0.5s ease;
  border-radius: 6px;

}
.li-button-field button:hover{
  background-position:left bottom;
  color:#fff;
  box-shadow: 0 0 10px 0 #000;
  background-color:#2d3e45
}

.TourHome-list-li hr{
  width:90%;
  border-width:0;
  margin:3%;
  background-color:#fff;
}
.button-field{
    display:flex;
    justify-content:center;
    align-content:center;
    flex-wrap:wrap;
    height:10vh;
    width:100%;
    padding: 3% 0;
}
.button-field button{
  height:55px;
  width:170px;
  background:linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position:right bottom;
  font-size:20px;
  font-weight:bold;
  cursor:pointer;
  border:none;
  transition:all 0.5s ease ;
  border-radius:10px;
}
.button-field button:hover{
  background-position:left bottom;
  color:#fff;
  box-shadow:0 0 10px 0 #000;
}
    .TourHome-content{
        height:100%;
        width:100%;
       padding-bottom:100px;
       background-color: #344e59;
      //  background-color:#7a8764;
    }
    .TourHome-list-ul{
        width:82%;
        margin:auto;
        display:grid;
        grid-template-columns: auto auto auto;
        gap:30px;
        list-style:none;
        padding-top: 25px;
        padding-left: 0;
    }
    @media screen and (max-width: 1290px) {
      .TourHome-list-ul{
        grid-template-columns: auto auto;
      }
    }
    .TourHome-list-li-image{
        height:290px;
        width:100%;
        object-fit: center;
        overflow:hidden;
    }
    .TourHome-list-ul li{
        transition: box-shadow 0.3s ease;
        width:400px;
        height: 600px;
        overflow:hidden;        
    }
    .TourHome-list-ul li:hover{
        box-shadow: 0 0 10px 0 #000;
        background-color:#2d3e45;
    }
    
    .TourHome-list-li-image img{
        width:100%;
        height:290px;
        transition: transform 0.3s ease ; 
        cursor:pointer;  
        position:center; 
    }
    
    .TourHome-list-li:hover img{
      transform: scale(1.143);
        
    }
    
    .TourHome-list-li-para{
        color:#fff;
        font-size:15px;
        padding:10px;
        transition:transform 0.3s ease;
    }
    .TourHome-list-ul li:hover .TourHome-list-li-para{
      transform: scale(0.99);
      

    }
    .TourHome-content{
        padding-top:80px;
        padding-bottom:0px
    }
    
   .TourHome-list-li h2{
        color:#fff;
        font-size:20px;
        padding:10px;
        padding-top:30px;
   }
   .TourHome-list-li-image-days{
    
   }
   table{
    color:#fff;
    padding:0 12px;
    width:100%;
   }
   tr,td{
    border-bottom: 1px solid #ccc;
    }
    tr:last-child td{
      border:none
    }
    @media screen and (max-width: 560px) {
      .TourHome-list-ul{
        grid-template-columns: auto ;
      }
      .TourHome-list-li-image img{
        
        height:190px;
      } 
      .TourHome-list-ul li{
        width:300px;
        height: 525px;
      }
      .TourHome-list-li-image{
        height:190px;
        
      }
      .TourHomeGrid-Header h2{
        font-size:25px;
      }
      .TourHomeGrid-Header h4{
        font-size:15px;
      }
      .button-field button {
        height: 40px;
        width: 165px;
    }
    
`;
