import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Footer from "../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Tours/Booking";
import FAQ from "../Tours/FAQ";
import Navbar from "../Navbar/Navbar";
// import Tour from "../JSON/Tours/T5.json";
import axios from "axios";
// import 'react-tabs/style/react-tabs.css';

let pageId = window.location.hash.slice(1);

export default function ToursCommon() {
  const backgroundImageUrl =
    "https://thetourpilot.com/resources//Images/TourPage/CostToCost/Banner/Tamil_Nadu.jpg";

  const [BgImage, setBgImage] = useState();
  const [TourTitle, setTourTitle] = useState();
  const [AboutTour1, setAboutTour1] = useState();
  const [AboutTour2, setAboutTour2] = useState();
  const [Highlights, setHighlights] = useState([]);
  const [DaysData, setDaysData] = useState([]);
  const [GalleryData, setGalleryData] = useState([]);
  const [TravelSketchData, setTravelSketchData] = useState([]);
  const [Tour, setTour] = useState([]);

  useEffect(() => {
    axios
      .get(`https://thetourpilot.com/resources/json/Tours/${pageId}.json`)
      .then((response) => {
        const TourData = response.data[0];
        

        setBgImage(TourData.bannerimage);
        setTourTitle(TourData.title);
        setAboutTour1(TourData.abouttour1);
        setAboutTour2(TourData.abouttour2);
        setHighlights(TourData.highlight);
        setGalleryData(TourData.galleryData);
        setTour([TourData]);
        setTravelSketchData(TourData.travelsketchData);
        setDaysData(TourData.daysData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        <div className="TourPage-Title">
          <h1>{TourTitle}</h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>{AboutTour1}</p>
                <p>{AboutTour2}</p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                {TravelSketchData.length>0 && (
                  <div>
                    <h6>Travel Sketch</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Location</th>
                          <th>Property</th>
                          <th>Meal Plan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TravelSketchData.map((i) => (
                          <tr key={i.day}>
                            <td>{i.day}</td>
                            <td>{i.location}</td>
                            <td>{i.accommodation}</td>
                            <td> {i.meals}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  {Highlights &&
                    Highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                {DaysData &&
                  DaysData.map((i, index) => (
                    <li key={index}>
                      <h6>
                        {i.day}: {i.location}
                      </h6>
                      <p>{i.description}</p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                {GalleryData &&
                  GalleryData.map((i, index) => (
                    <img key={index} src={i} alt="" />
                  ))}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#344e597a;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:100;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px;
        background-color: #344e59;
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#344e59;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #344e59;
        color: #fff;
    }
    .react-tabs__tab-panel {
      background-color: #344e59;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
        margin-top:5px;
        font-weight:100;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        margin-top:20px;
      }
      
    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      .galleryContainer{
        background-color: #344e59;
      }
      .gallery img{
        height:250px;
        width:400px;
      }
      @media(max-width:576px){
        .TourPage-banner{
          height: 40vh;
        }
        .react-tabs__tab-list{
          flex-direction: column;
          height:209px; 
          width: 86%;
        }
        .AboutTour {
          padding-top: 40px;
          padding-bottom: 40px;
          width: 92%;
      }
      .AboutTour-content p{
        font-size: 12px;
      }
      .Highlights{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 92%;
      }
      .TourPage-Overview {
        font-size: 10px;
      }
      .Highlights ul {
        font-size: 13px;
      }
      .AboutTour-ul {
        height: 27vh;
        width: 76%;
      }
      .AboutTour-ul ul{
        padding-left: 10px;
      }
      .TourPage-Title h1{
        font-size: 1.3em;
      }
      .Itinerary {
        width:100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .wrapper {
        padding-right: 10px;
        padding-left: 2px;
      }
      .Itinerary-content ul{
        padding-top: 5px;
        padding-left:30px;
      }
      .Itinerary-content h6{
        font-size: 20px;
    }
    .Itinerary-content p{
      font-size: 15px;
    }
    .gallery img{
      width:300px;
      height:180px;
    }
    }
    }
`;
