import React from "react";

export default function homeGallery(){

    return(
        <>
        <style>{css}</style>
            <div className="homehomeGalleryContainer">
                <div className="homeGallerytopic">
                <h3>Gallery</h3>
                </div>
                
                <div className="homehomeGalleryBox ">
                    <div className="homeGallery">
                        <img src={require('../Images/Home/Gallery/IMG_20171221_115751173_HDR.jpg')} alt="" height={250} width={400} />
                        <img src={require('../Images/Home/Gallery/Untitled-1.jpg')} alt="" height={250} width={400} />
                        <img src={require('../Images/Home/Gallery/Untitled-2.jpg')} alt="" height={250} width={400} />
                        <img src={require('../Images/Home/Gallery/Untitled-3.jpg')} alt="" height={250} width={400} />
                        <img src={require('../Images/Home/Gallery/IMG_20190120_122044.jpg')} alt="" height={250} width={400} />
                        <img src={require('../Images/Home/Gallery/Untitled-4.jpg')} alt="" height={250} width={400} />
                        

                    </div>
                </div>
            </div>
        </>
    )
}

const css = `
.homehomeGalleryContainer{
    height: 100%;
    /* width: 100vw; */
    background-color: #344e59;
    padding-top:5%;
    // background-color: #7a8764;
}
  .homeGallerytopic{
    width:78%;
    margin:0 auto;
  }
  .homehomeGalleryContainer .homeGallerytopic h3{
    text-align: left;
    padding-top: 3%;
    padding-bottom: 1%;;
    color: #fff;
    font-size:50px;
  }
  
  .homehomeGalleryBox{
   display:flex;
   justify-content: center;
   width: 100%;
  }
  
  .homeGallery{
  /* width: 78.5%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  }
  .homeGallery img{
    height:250px;
    width:400px;
  }
  @media(max-width:576px){
    .homeGallery img{
        height: 100px;
        width: 163px;
    }
    .homehomeGalleryContainer .homeGallerytopic h3{
        font-size:18px;
    }
  }
  `

