import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/KochiKovalam/Banner/Kovalam_banner.jpg";
import Footer from "../../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Booking";
import "react-tabs/style/react-tabs.css";
import FAQ from "../FAQ";
// page-id = 4

export default function KochiKovalamviaTN() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>
            Kerala Motorcycle Odyssey: From Kochi to Kovalam Through Scenic
            Wonders
          </h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  Embark on the Kochi Kolavam Tour, a spellbinding journey that
                  traverses the captivating terrain of Southern India. Beginning
                  in the historic port city of Kochi, where ancient spice
                  markets still whisper tales of maritime trade, the voyage
                  leads you to Guruvayoor, a spiritual sanctuary resonating with
                  the melodious echoes of age-old temples. The expedition then
                  veers towards Attapadi, a tranquil enclave nestled amidst the
                  verdant embrace of the Western Ghats, where indigenous culture
                  and pristine wilderness converge, offering a glimpse into a
                  harmonious coexistence with nature.
                </p>
                <p>
                  TAs the tour unfolds, you'll find yourself in the serene town
                  of Pollachi, where languid rivers and coconut groves paint a
                  soothing landscape before ascending to the misty heights of
                  Munnar. Here, emerald tea plantations and rugged mountains
                  create a visual symphony that stirs the soul. The adventure
                  continues in Thekkady, where the Periyar National Park invites
                  you to explore its rich wildlife and untamed beauty. Vagamon,
                  with its lush meadows and rolling hills, provides a tranquil
                  respite before the grand finale at Courtallam, known as the
                  "Spa of Southern India," where the cascade of waterfalls and
                  therapeutic baths complete this odyssey through the diverse
                  and enchanting landscapes of South India. The Kochi Kolavam
                  Tour is more than a mere journey; it's an immersion into the
                  myriad wonders of this remarkable region, where history,
                  spirituality, and nature converge to create a tapestry of
                  unforgettable experiences.
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Travel Sketch</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Location</th>
                      <th>Property</th>
                      <th>Meal Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Day 1</td>
                      <td>Kochi</td>
                      <td>Old Harbour</td>
                      <td> -/-/D</td>
                    </tr>
                    <tr>
                      <td>Day 2</td>
                      <td>Guruvayoor</td>
                      <td>Sitaram Beach Retreat - Ayurvedic Resort</td>
                      <td>B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 3</td>
                      <td> Attapadi</td>
                      <td>Sterling Anakatti</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 4</td>
                      <td>Pollachi</td>
                      <td>Coco Lagoon by Great Mount</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 5</td>
                      <td>Kodaikanal</td>
                      <td>Hotel Grand Palace</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 6</td>
                      <td>Munnar</td>
                      <td>Kaivalyam Retreat</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 7</td>
                      <td>Thekkady</td>
                      <td> Grand Thekkady</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 8</td>
                      <td>Courtallam</td>
                      <td>Saaral Resort</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 9</td>
                      <td>Kovalam</td>
                      <td>Soma Palm Shore</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 10</td>
                      <td>Alleppy</td>
                      <td>House Boat</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 11</td>
                      <td>Fort Kochi</td>
                      <td>Old Harbour</td>
                      <td> B/L/D</td>
                    </tr>
                    <tr>
                      <td>Day 12</td>
                      <td>Depart</td>
                      <td></td>
                      <td> B/-/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>
                    Enjoy a picturesque motorcycle ride through the beautiful
                    landscapes of Anakatti and Pollachi. These regions are known
                    for their lush greenery, scenic vistas, and serene
                    surroundings.
                  </li>
                  <li>
                    Take a detour to Kodaikanal, a charming hill station nestled
                    in the Western Ghats
                  </li>
                  <li>Tea-plantations and spice plantation walks</li>
                  <li>
                    Ride through the tea plantations of Munnar, a hill station
                    famous for its expansive tea gardens
                  </li>
                  <li>
                    Embark on a wildlife adventure in Thekkady, home to the
                    Periyar National Park
                  </li>
                  <li>
                    Visit Courtallam, also known as the "Spa of South India,"
                    and witness the mesmerizing Courtallam Waterfalls
                  </li>
                  <li>
                    Relax on the sandy shores, take a dip in the Arabian Sea,
                    and witness a beautiful sunset at Kovalam Beach
                  </li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Motorcycles</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>Day 1: Kochi</h6>
                  <p>
                    Pickup from Cochin International airport transfer to your
                    hotel at FortKochi. A rest day to beat your jet lag and get
                    set for your motorcycling journey in the following days. In
                    the evening we will fix your motorcycles and tour briefing
                    followed by dinner.
                  </p>
                </li>
                <li>
                  <h6>Day 2: Guruvayoor</h6>
                  <p>
                    Get ready for an exhilarating motorcycling adventure from
                    Kochi to Guruvayoor, tracing a route that promises not just
                    the thrill of the ride but also the scenic beauty of the
                    coastal landscape. The journey kicks off in Kochi, a
                    historic port city known for its vibrant culture and
                    colonial influences. As you rev up your motorcycle, the
                    picturesque route unfolds, leading you through the charming
                    Cherai Beach. Feel the coastal breeze and witness the serene
                    beauty of this sandy stretch against the backdrop of the
                    Arabian Sea. The ride along the coast offers riders a
                    perfect blend of the open road and the calming presence of
                    the sea, setting the stage for an unforgettable journey.{" "}
                  </p>
                  <p>
                    As you approach Guruvayoor, the air becomes infused with a
                    spiritual aura as the town is home to the revered Guruvayoor
                    Temple dedicated to Lord Krishna. The temple's intricate
                    architecture and spiritual ambiance make it a significant
                    pilgrimage site. Park your motorcycle and explore the sacred
                    grounds, immersing yourself in the cultural and religious
                    heritage of Guruvayoor. This motorcycling expedition from
                    Kochi to Guruvayoor via Cherai Beach not only promises the
                    thrill of the open road but also unveils the rich tapestry
                    of Kerala's coastal and cultural treasures.
                  </p>
                </li>
                <li>
                  <h6>Day 3: Anaikatti</h6>
                  <p>
                    Embarking on a motorcycle ride from Guruvayoor to Anaikatti
                    unveils a fascinating journey that traverses diverse
                    landscapes and cultural nuances. Guruvayoor, known for its
                    spiritual charm, provides a serene starting point for the
                    adventure. As you hit the road, the lush greenery of Kerala
                    surrounds you, offering a refreshing contrast to the temple
                    town's spiritual ambiance. The ride takes you through
                    winding roads and vibrant towns, showcasing the authentic
                    essence of Kerala's countryside.
                  </p>
                  <p>
                    Heading towards Anaikatti, you'll witness the landscape
                    gradually transitioning from the coastal beauty of
                    Guruvayoor to the picturesque terrain of the Western Ghats.
                    Anaikatti, nestled amidst the hills, welcomes you with its
                    tranquility and natural splendor. The winding roads leading
                    to Anaikatti offer motorcyclists a perfect blend of
                    thrilling twists and turns, making the journey as
                    exhilarating as the destination. This motorcycle ride from
                    Guruvayoor to Anaikatti is not just a road trip; it's a
                    captivating exploration of Kerala's diverse landscapes and
                    the joy of cruising through its cultural heart.
                  </p>
                </li>
                <li>
                  <h6>Day 4: Pollachi</h6>
                  <p>
                    The motorcycle journey from Anaikatti to Pollachi unfolds
                    like a captivating chapter in a travelogue, with each twist
                    and turn revealing the unique charm of the route. Leaving
                    behind the hills of Anaikatti, the road descends into the
                    lush plains of Tamil Nadu, offering riders a seamless
                    transition from elevated terrain to expansive landscapes. As
                    the motorcycle glides through the scenic countryside, the
                    air becomes filled with the fragrance of blooming flowers
                    and the sight of verdant fields. The ride to Pollachi is a
                    sensory delight, allowing riders to immerse themselves in
                    the changing scenery, from the hilly retreat of Anaikatti to
                    the vibrant, fertile plains surrounding Pollachi. The
                    motorcycle journey captures the essence of the journey as
                    much as the destination, making it a memorable experience
                    for riders seeking the thrill of exploration.
                  </p>
                </li>
                <li>
                  <h6>Day 5: Kodaikanal</h6>
                  <p>
                    Navigating the roads from Pollachi to Kodaikanal on a
                    motorcycle is a riveting adventure that invites riders to
                    traverse the diverse landscapes of Tamil Nadu. Departing
                    from the lively town of Pollachi, riders gradually ascend
                    into the Western Ghats, where the air becomes crisp and the
                    surroundings transform into a picturesque panorama. The
                    winding roads offer exhilarating twists and turns, revealing
                    hidden gems of nature along the way. As riders climb higher,
                    the temperature drops, signaling the approach to the hill
                    station paradise of Kodaikanal. The motorcycle journey from
                    Pollachi to Kodaikanal encapsulates the thrill of the open
                    road and the anticipation of reaching the enchanting heights
                    of this sought-after destination.
                  </p>
                </li>
                <li>
                  <h6>Day 6: Munnar</h6>
                  <p>
                    Embarking on a thrilling motorcycle ride from Kodaikanal to
                    Munnar unveils a captivating journey through the Western
                    Ghats' lush landscapes. The route meanders through scenic
                    mountainous terrain, treating riders to breathtaking views
                    and invigorating mountain air. Riding along the winding
                    roads, the transition from Kodaikanal's charm to Munnar's
                    misty hills is a seamless immersion into nature's grandeur.
                    The journey is not merely a passage but an exploration,
                    where each turn opens up new vistas, and the roar of the
                    motorcycle harmonizes with the sounds of the verdant
                    surroundings. Riding from Kodaikanal to Munnar is an
                    unforgettable odyssey, weaving through the heart of South
                    India's elevated beauty.
                  </p>
                </li>
                <li>
                  <h6>Day 7: Thekkady</h6>
                  <p>
                    Venturing from Munnar to Thekkady on a motorcycle is an
                    exhilarating expedition through the picturesque landscapes
                    of Kerala. The journey encompasses a seamless blend of
                    vibrant tea plantations, meandering roads, and the enticing
                    allure of the Western Ghats. As riders traverse the winding
                    paths, they are greeted by the refreshing aroma of tea
                    leaves and the mystique of dense forests. The road unfurls
                    like a ribbon, guiding bikers through the enchanting terrain
                    that connects these two gems of Southern India. The Munnar
                    to Thekkady motorcycle ride is not just a passage; it's a
                    sensory experience, where the hum of the engine resonates
                    with the natural symphony, creating memories etched in the
                    scenic beauty of Kerala's highlands.
                  </p>
                </li>
                <li>
                  <h6>Day 8: Courtallam</h6>
                  <p>
                    YEmbarking on a motorcycle journey from Thekkady to
                    Courtallam unveils a mesmerizing passage through the heart
                    of Kerala's diverse landscapes. As riders navigate the
                    winding roads, the air becomes filled with the invigorating
                    scent of spices from the lush plantations that dot the
                    region. The journey unfolds like a tapestry of greenery,
                    with the Western Ghats standing tall on the horizon. Passing
                    through quaint villages, the motorcycle ride offers glimpses
                    of local life, adding cultural richness to the natural
                    beauty. Riding through this captivating stretch, bikers are
                    treated to the sounds of nature, creating a symphony that
                    accompanies them on this unforgettable route to Courtallam.
                  </p>
                </li>
                <li>
                  <h6>Day 9: Kovalam</h6>
                  <p>
                    Navigating the scenic route from Courtallam to Kovalam on a
                    motorcycle is a captivating experience that seamlessly
                    blends nature's beauty with cultural exploration. The
                    journey takes riders through diverse landscapes, from the
                    serene hills surrounding Courtallam to the sandy shores of
                    Kovalam. As bikers traverse the winding roads, they witness
                    the transition from the lush greenery of the Western Ghats
                    to the coastal allure of the Arabian Sea. The ride
                    introduces glimpses of local life, with traditional villages
                    and vibrant markets contributing to the cultural tapestry of
                    the journey. Culminating in the sun-kissed beaches of
                    Kovalam, this motorcycle adventure from Courtallam is a
                    celebration of the picturesque vistas and the rich heritage
                    that defines the southern coast of India.
                  </p>
                </li>
                <li>
                  <h6>Day 10: Alleppey</h6>
                  <p>
                    Embarking on a thrilling motorcycle journey from Kovalam to
                    Alleppey unveils the enchanting coastal beauty of Kerala.
                    Riding along the scenic coastal roads, bikers are treated to
                    a mesmerizing panorama of the Arabian Sea stretching
                    endlessly to the horizon. The journey unfolds with the
                    rhythmic sound of waves crashing against the shoreline,
                    creating a symphony that accompanies riders along the route.
                    As the coastal breeze sweeps through, it carries the salty
                    aroma of the sea, enhancing the sensory experience. The
                    picturesque villages and coconut groves that line the path
                    add a touch of local charm, offering riders a glimpse into
                    the laid-back lifestyle of the coastal communities.
                    Ultimately, the ride culminates in Alleppey, where the
                    tranquil backwaters and serene landscapes provide a perfect
                    conclusion to this coastal motorcycle adventure.
                  </p>
                </li>
                <li>
                  <h6>Day 10: Fort Kochi</h6>
                  <p>
                    Navigating the coastal roads from Alleppey to Fort Kochi on
                    a motorcycle is a delightful expedition for those seeking a
                    blend of scenic beauty and cultural richness. The journey
                    unfolds along the picturesque coastline of the Arabian Sea,
                    offering riders panoramic views of the azure waters and
                    sandy shores. As bikers cruise through quaint fishing
                    villages, they witness the vibrant local life and the daily
                    activities of fishermen. The salty breeze from the sea
                    accompanies riders, creating a refreshing atmosphere
                    throughout the ride. Approaching Fort Kochi, the landscape
                    transitions to a historic ambiance, marked by colonial
                    architecture, ancient churches, and charming streets that
                    tell tales of the region's diverse heritage. This coastal
                    motorcycle ride is a captivating experience, seamlessly
                    merging natural beauty with cultural exploration.
                  </p>
                </li>
                <li>
                  <h6>Day 12: Departure</h6>
                  <p>
                    After the delicious breakfast from the Hotel you will be
                    transferred to the airport at Cochin to bid adieu to Kerala
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/untitled1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/ghat.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/jog_falls.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiGoa/Gallery/IMG_20190115_180644.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:100;
    }
    h6{
        
        font-size: 30px;
    }
    .Itinerary-content p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        height:40px;
         font-size:15px;
         font-weight:bold;
         // border-bottom: 0px solid #aaa;
         //  margin: 0 0 0px; 
         //  padding: 0;
         //  background-color:#7a8764;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
        margin-top:5px;
        font-weight:100;
    }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        margin-top:20px;
      }
      
    th, td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      @media(max-width:576px){
        .react-tabs__tab-list{
          flex-direction: column;
          height:209px; 
          width: 86%;
        }
        .AboutTour {
          padding-top: 40px;
          padding-bottom: 40px;
          width: 92%;
      }
      .AboutTour-content p{
        font-size: 12px;
      }
      .Highlights{
        padding-top: 30px;
        padding-bottom: 30px;
        width: 92%;
      }
      .TourPage-Overview {
        font-size: 10px;
      }
      .Highlights ul {
        font-size: 13px;
      }
      .AboutTour-ul {
        height: 27vh;
        width: 76%;
      }
      .AboutTour-ul ul{
        padding-left: 10px;
      }
      .TourPage-Title h1{
        font-size: 1.3em;
      }
      .Itinerary {
        width:100%;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .wrapper {
        padding-right: 10px;
        padding-left: 2px;
      }
      .Itinerary-content ul{
        padding-top: 5px;
        padding-left:30px;
      }
      .Itinerary-content h6{
        font-size: 20px;
    }
    .Itinerary-content p{
      font-size: 15px;
    }
    .gallery img{
      width:300px;
      height:180px;
    }
    }
`;
