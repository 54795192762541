import React from 'react';
import Navbar from '../Navbar/Navbar'
import Spotlight from './Spotlight';

import Gallery from './Gallery';
import Icons from './Icons';
import SliderImage from './SliderImage';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import TourHomeGrid from './TourHomeGrid';
import FormPage from './FormPage';
import AboutCompany from './AboutCompany';



function HomeMain(){



    return(
            <>
            <Helmet>
                <title>The Tour Pilot | Home</title>
            </Helmet>
                <style>
                    {css}
                </style>
                <Navbar />
                <Spotlight />
                <AboutCompany />
                <SliderImage />
                <TourHomeGrid />
                <div className='home-image'>

                </div>
                <Gallery />
               <FormPage />
                <Footer />
                
            </>
            
    )
}

const css =`
    
}
`

export default HomeMain;