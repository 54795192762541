
import './App.css';
import HomeMain from './Components/Home/HomeMain';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import TourHome from './Components/Tours/TourHome';
import FortKochiDayCycling from './Components/Tours/FortKochiDayCycling/FortKochiDayCycling';
import KochiWalking from './Components/Tours/KochiWalking/KochiWalking';
import KochiGoa from './Components/Tours/KochiGoa/KochiGoa';
import KochiKovalamviaTN from './Components/Tours/KochiKovalamviaTN/KochiKovalamviaTN';
import CostToCost from './Components/Tours/CostToCost/CostToCost';
import BikeTrekKayak from './Components/Tours/BikeTrekKayak/BikeTrekKayak';
import Kayaking from './Components/Tours/Kayaking';
import Trekking from './Components/Tours/Trekking';
import Motorcycling from './Components/Tours/Motorcycling';
import Cycling from './Components/Tours/Cycling';
import DestinationHome from './Components/Destination/DestinationHome';
import DestinationPlaces from './Components/Destination/DestinationPlaces';
import AboutUs from './Components/OtherPages/AboutUs';
import ContactUs from './Components/OtherPages/ContactUs';
import ToursCommon from './Components/Tours/ToursCommon';




function App() {
  return (
  <>
    <Router>
      <Routes>
        <Route Component={HomeMain} exact path='/'/>
        <Route Component={TourHome} path='/Tours'/>
        <Route Component={FortKochiDayCycling} path='/Tours/1'/>
        <Route Component={KochiWalking} path='/Tours/2'/>
        <Route Component={KochiGoa} path='/Tours/3'/>
        <Route Component={KochiKovalamviaTN} path='/Tours/4'/>
        <Route Component={CostToCost} path='/Tours/5'/>
        <Route Component={BikeTrekKayak} path='/Tours/6'/>
        <Route Component={Kayaking} path='/Tours/kayaking'/>
        <Route Component={Trekking} path='/Tours/trekking'/>
        <Route Component={Motorcycling} path='/Tours/motorcycling'/>
        <Route Component={Cycling} path='/Tours/cycling'/>
        <Route Component={DestinationHome} path='/Destination'/>
        <Route Component={DestinationPlaces} path='/DestinationPlace'/>
        <Route Component={AboutUs} path='/AboutUs' />
        <Route Component={ContactUs} path='/ContactUs'/>
        <Route Component={ToursCommon} path='/ToursDetails'/>
      </Routes>
    </Router>
  </>
  )
}

export default App;
