import React, { useRef, useState, useEffect } from "react";

export default function SliderImage() {
  const courselRef = useRef(null);
  const cardRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(1);

  const handleDotClick = (index) => {
    setSelectedSlide(index);
    scrollToSlide(index);
  };
  const scrollToSlide = (index) => {
    cardRef.current.scrollTo({
      left: index * cardRef.current.offsetWidth,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    cardRef.current = courselRef.current;
  }, []);

  return (
    <>
      <style>{css}</style>
      <div className="mainslidercomponent">
        {/* <i>
          <img src={require("../Images/Home/SIlder/left-arrow.png")} alt="" />
        </i> */}
        <h2>Journeys Shared</h2>
        <h4 >In the Words of Our Explorers</h4>
        <ul className="carousel" ref={courselRef}>
          <li className="card" ref={cardRef} onMouseEnter={() => handleDotClick(1)}>
            <div className="card-left">
              <img
                src={require("../Images/Home/SIlder/IMG_20180116_145541542_HDR.jpg")}
                alt=""
              />
            </div>
            <div className="card-right">
              <p>
                I recently had the pleasure of embarking on a combined trekking
                and motorcycle tour from Kochi to Munnar with this incredible
                company, and I am still in awe of the amazing experience I had.
                This journey was nothing short of magical, filled with
                breathtaking landscapes, thrilling adventures, and unforgettable
                moments.They went above and beyond to ensure every detail was
                taken care of, making the entire experience smooth and
                enjoyable.
              </p>
              <span>
                <i class="fa fa-compass" aria-hidden="true"></i>
                <h6>Nick</h6>
              </span>
              <div className="rating">
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
              </div>
            </div>
          </li>
          <li className="card" ref={cardRef} >
            <div className="card-left">
              <img
                src={require("../Images/Home/SIlder/IMG_20190114_101742.jpg")}
                alt=""
              />
            </div>
            <div className="card-right">
              <p>
                I recently embarked on a thrilling motorcycle tour from Kochi to
                Goa, organized by this exceptional company, and I cannot express
                how incredible the experience was. From the moment I hopped on
                my bike to the final stretch in the mesmerizing land of Goa,
                every moment was filled with adventure, excitement, and a
                profound sense of freedom.The team was not only professional but
                also friendly and accommodating.
              </p>
              <span>
                <i class="fa fa-compass" aria-hidden="true"></i>
                <h6> Jean-Phillipe</h6>
              </span>
              <div className="rating">
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
              </div>
            </div>
          </li>
          <li className="card">
            <div className="card-left">
              <img
                src={require("../Images/Home/SIlder/IMG_20190214_115446 (1).jpg")}
                alt=""
              />
            </div>
            <div className="card-right">
              <p>
                Great cycling adventure in Kerala! The equipment was top, the
                service very attentive and I felt I was travelling with a
                friend. We discovered a lot of Kerala treasures and stayed in
                very nice and comfortable hotels. Then we explored the
                backwaters by kayak and had a chance to meet local people and
                know about their culture and traditions. Finally we discovered
                Kochi without feeling as a tourist! I recommend this local
                company which has tons to offer!!
              </p>
              <span>
                <i class="fa fa-compass" aria-hidden="true"></i>
                <h6>Bijou Kalayil</h6>
              </span>
              <div className="rating">
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
                <i class="fa fa-star" aria-hidden=""></i>
              </div>
            </div>
          </li>
        </ul>
        {/* <i>
          <img src={require("../Images/Home/SIlder/right-arrow.png")} alt="" />
        </i> */}
        <div className="slider-dots">
          <ul className="slider-dots-ul">
            <li
              className={`slider-dots-ul-li ${
                selectedSlide === 0 ? "active" : ""
              }`}
            >
              <i
                class="fa fa-circle"
                aria-hidden="true"
                onMouseEnter={() => handleDotClick(0)}
              ></i>
            </li>
            <li
              className={`slider-dots-ul-li ${
                selectedSlide === 1 ? "active" : ""
              }`}
            >
              <i
                class="fa fa-circle"
                aria-hidden="true"
                onMouseEnter={() => handleDotClick(1)}
              ></i>
            </li>
            <li
              className={`slider-dots-ul-li ${
                selectedSlide === 2 ? "active" : ""
              }`}
            >
              <i
                class="fa fa-circle"
                aria-hidden="true"
                onMouseEnter={() => handleDotClick(2)}
              ></i>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

const css = `
.slider-dots{
  width:400px;
  margin:auto;
  margin-top:10px;
}
.slider-dots-ul{
  display:flex;
  justify-content: center;
  list-style: none;
  padding-left:0;
}
.slider-dots-ul-li{
  width:17px;
  margin:5px;
  cursor:pointer;
}
.slider-dots-ul-li:hover i{
  font-size:16px;
  width:20px;
}
.slider-dots-ul-li i{
  font-size:13px;
  color:#fffF;
  transition:font-size 0.3s ease;
}

.mainslidercomponent{
  padding: 112px;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  background-color: #344e59;
  background-color: #344e59;
  padding-bottom:10px;
  // background:#7a8764;
}

i img{
  height: 20px;
  width: 20px;
}
.carousel{
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 80px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  white-space: nowrap;
  margin-top:30px;
  // grid-auto-columns: calc((100% / 1) - 12px);
}
.carousel .card {
  scroll-snap-align: center;
  height: 342px;
  width:900px;
  list-style: none;
  cursor: pointer;
  display:flex;
  justify-content:space-between;
  background:#2d3e45;
  border-radius:40px;
  padding:0px 40px;
}
.card-left{
    width:300px;
    height:100%;
    border-radius:10px;
    display:flex;
    flex-wrap:wrap;
    align-content:center;
    
}
.card-right{
    width:500px;
    height:100%;
    display:flex;
    flex-direction:column;
    text-wrap:wrap;
    padding-right:27px;
  }
.card-right p{
      font-size: 17px;
      font-weight:100;
      text-align:center;
      margin-top:80px;
      color:#fff;
  }
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}
.carousel.dragging .card {
  cursor: grab;
  user-select: none;
}
.carousel .card  .img{
  height: 280px;
    width: 280px;
  
    overflow:hidden;
}
.carousel .card  img {
  background: #8B53FF;
  height: 300px;
  width: 300px;
  border-radius:50%;
  object-fit: cover;
  margin:auto;
  border: 4px solid #fff;
}
.card-right span{
width:500px;
display:flex;
justify-content:center;
padding-top: 10px;
}
.card-right h6{
  color:#fff;
  text-align:center;
  font-size:20px;
  padding-top:10px;
}
.card-right span i{
  font-size:30px;
  margin: 9px;
  margin-right: 14px;
}
.rating{
display: flex;
justify-content: center;

}
.card-right i{
  font-size:17px;
  color:#fff;
  margin-left:8px;
}
.mainslidercomponent h2{
  color:#fff;
  text-align:left;
  font-Size:50px;
}
.mainslidercomponent h4{
color:#fff;
text-align:left;
font-size:20px;
}
@media (max-width:560px){
  .mainslidercomponent h2{
    font-Size:25px;
  }
  .mainslidercomponent h4{
    font-size:14px;
  }
  .mainslidercomponent{
    padding: 38px;
    min-height: 35vh;
  }
  .carousel .card {
    height: 188px;
    width: 200px;
  }
  .carousel .card img{
    height: 100px;
    width: 100px;
  }
  .card-left{
    width:0px;
    display:none;
  }
  .card-right{
    width: 200px;
  }
  .card-right p{
    margin-top: 19px;
    font-size: 8px;
  }
  .card-right span{
    width: 200px;
  }
  .card-right span i{
    font-size: 16px;
    margin: 6px;
    margin-right: 12px;
  }
  .card-right h6{
    font-size: 11px;
    padding-top: 5px;
  }
  .card-right i{
    font-size: 9px;
  }
  .slider-dots{
    width:200px;
  }
  .slider-dots-ul-li i{
    font-size: 8px;
  }
  .carousel {
    gap:20px;
  }
}

`;
