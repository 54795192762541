import React from 'react'

export default function FAQ() {
  return (
    <>
    <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>
                    What kind of fitness level do I need to enjoy a trip ?
                  </h6>
                  <p>
                    A minimum fitness level is required for our Casual,Motorcycle and
                    Premium Bicycle trips. In the case of a Shared or Group tour
                    please make sure you have good fitness levels to bike long
                    distance and keep pace with the rest of the group. Day tours
                    require basic biking skills. The concept of traveling on a
                    bi-cycle is to take in the aromas and to see a real world.
                    Join us to experience a real India.
                  </p>
                </li>
                <li>
                  <h6>Riding Flexibility ?</h6>
                  <p>
                    We will also be having a back-up vehicle accompanying
                    through-out the tour and therefore, the cycling distance
                    mentioned in the itinerary can be made flexible depending on
                    your interest and fitness level.
                  </p>
                </li>
                <li>
                  <h6>Is it safe to ride in India ?</h6>
                  <p>
                    Routes we take you on are routes we’ve biked ourselves over
                    and over again. Activities we’ve planned on these trips are
                    activities we love and those you’d love too. Our routes are
                    safe ! Most often using those through village and back-roads
                    with minimum or zero traffic. India is a safe place and with
                    us you can expect to be a 100% safe and secure. This is our
                    guarantee.
                  </p>
                </li>
                <li>
                  <h6>Can I bring my own bike ?</h6>
                  <p>
                    Of course you could get your own bike. Please make sure to
                    bring along necessary tools and spares as we do not
                    guarantee availability of spares for personal bikes.
                  </p>
                </li>
                <li>
                  <h6>Vaccinations</h6>
                  <p>
                    There are no compulsory vaccinations for foreign visitors
                    but we strongly recommended that you should contact your
                    doctor at least 3 months prior to travel to obtain these
                    vaccinations and the latest advice to travelers. Expert
                    advice on medication should be sought.
                  </p>
                </li>
                <li>
                  <h6>First Aid</h6>
                  <p>
                    Our guides carry a medical kit. We recommend that you bring
                    your own first aid supplies including: a broad spectrum
                    anti-biotic, antiseptic cream, throat lozenges, a cold
                    medication, diarrhea treatment, pain killers, plasters,
                    insect repellent and re-hydration salts.
                  </p>
                </li>
                <li>
                  <h6>Travel Insurance</h6>
                  <p>
                    It is a condition of joining our trips that you must be
                    adequately insured. Polices should cover potential risks
                    involved in a mountain cycling holiday. We ask for details
                    of your policy to be provided prior to travel. We recommend
                    that you arrange your travel insurance at the same time as
                    booking your holiday so that you are covered in the event of
                    cancellation.
                  </p>
                </li>
                <li>
                  <h6>Accommodation / Meals</h6>
                  <p>
                    The properties that we have suggested in the itinerary are
                    standard and premium properties we use and include heritage
                    hotels, Eco-lodges and Plantation Retreats. Indian food is
                    mainly vegetarian and spicy but not hot curry. A typical
                    meal consists of rice, Chappathy, fish, and vegetables.
                    There is an abundance of fresh fruit and fish. Mineral water
                    is widely available and carried on the support vehicle.
                  </p>
                </li>
              </ul>
            </div>
          </div>
    </>
  )
}
