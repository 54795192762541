import React, { useEffect, useState } from "react";
import BgImage3 from "../Images/Home/main-spotlight/one_song.mp4";


function Spotlight({ phrases, animation, speed }) {
  const [currentPhraseIndex, setcurrentPhraseIndex] = useState(0);
  const [BgCount, setBgCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const newIndex = (currentPhraseIndex + 1) % phrases.length;
      setcurrentPhraseIndex(newIndex);
    }, speed);

    return () => {
      clearTimeout(timer);
    };
  }, [currentPhraseIndex, phrases, speed]);

  return (
    <>
      <style>{css}</style>

      <div className="main-spotlight">
        <div className="spot-top ">
          <video autoPlay muted loop className="video-bg">
            <source src={BgImage3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h3></h3>
          {/* <div className="rotating-text-field">
          <div
            className={`rotating-text ${animation}`}
            style={{ color: "#fff" }}
          >
            PLAN YOUR <b>{phrases[currentPhraseIndex]}</b>
          </div>
          <p>
            “Travel is the main thing you purchase that makes you more
            extravagant”
          </p>
          </div> */}
          {/* <div className="spot-top-content">
                    <div className="top-left" onMouseEnter={()=>{mouseTrigger("1")}} style={{width:width1}} onMouseLeave={()=>{
                      setwidth1(600)
                      setwidth2(200)
                      setwidth3(200)
                    }
                    }>
                        
                        <video src={require('../Images/Home/Videos/video_out.mp4')} type="video/mp4" 
                        autoPlay muted loop width={"100%"} height={"100%"}/>
                    </div>
                    <div className="top-middle" onMouseEnter={()=>{mouseTrigger("2")}} style={{width:width2}} onMouseLeave={()=>{
                      setwidth1(600)
                      setwidth2(200)
                      setwidth3(200)
                    }
                    }>
                      <img src={require('../Images/Home/main-spotlight/IMG_20171220_120905069.jpg')} alt="" height={"100%"} />
                    </div>
                    <div className="top-right" onMouseEnter={()=>{mouseTrigger("3")}} style={{width:width3}} onMouseLeave={()=>{
                      setwidth1(600)
                      setwidth2(200)
                      setwidth3(200)
                    }
                    }>
                      <img src={require('../Images/Home/main-spotlight/IMG_20180109_221024_850.jpg')} alt="" height={"100%"} />
                    </div> */}
          {/* </div> */}
        </div>
        <div className="spot-bottom">
          {/* <div className="icon-list">
            <ul>
              <li>
                <img
                  src={
                    require("../Images/Home/Spotlight/bicycle-svgrepo-com.svg")
                      .default
                  }
                  alt=""
                  height={100}
                  width={70}
                />
                <div className="popup-bubble">Cycling</div>
              </li>
              <li>
                <a href="/Tours/kayaking">
                  <img
                    src={
                      require("../Images/Home/Spotlight/canoe-svgrepo-com.svg")
                        .default
                    }
                    alt=""
                    height={100}
                    width={70}
                  />
                  <div className="popup-bubble">Kayaking</div>
                </a>
              </li>
              <li>
                <a href="/Tours/cycling">
                  <img
                    src={
                      require("../Images/Home/Spotlight/bicycle-svgrepo-com.svg")
                        .default
                    }
                    alt=""
                    height={100}
                    width={70}
                  />
                  <div className="popup-bubble">Cycling</div>
                </a>
                
              </li>
              <li>
                <a href="/Tours/motorcycling">
                  <img
                    src={
                      require("../Images/Home/Spotlight/helmet-motorcycle-svgrepo-com.svg")
                        .default
                    }
                    alt=""
                    height={100}
                    width={70}
                  />
                </a>
              </li>
              <li>
                <a href="/Tours/trekking">
               
                  <img
                    src={
                      require("../Images/Home/Spotlight/icons8-trekking-64.png")
                      
                    }
                    alt=""
                    height={70}
                    width={70}
                    style={{margin:"14px"}}
                  />
                   <div className="popup-bubble">Trekking</div>
                </a>
               
              </li>
              <li>
                <img
                  src={
                    require("../Images/Home/Spotlight/rickshaw-svgrepo-com.svg")
                      .default
                  }
                  alt=""
                  height={100}
                  width={70}
                />
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
}

Spotlight.defaultProps = {
  phrases: ["TRIPS", "DREAMS", "HAPPINESS"],
  animation: "fadeIn", // You can set your desired animation class here
  speed: 2000,
};

const css = `
       .main-spotlight {
            left: 0;
            background-color: #344e59;
            background-color: #fff;  
            // height: 100vh;
          }
          .rotating-text-field{
            z-index:999;
            background-color:#25380466;
            background-color: #ffffff42;  
            width:100%;
            position:absolute;
            top:65%;
          }
        .rotating-text{
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            padding-top:1%;
            // bottom:100%;
            }
            .rotating-text-field p{
              text-align: center;
              fonr-weight:bold;
              font-size:20px;
              color:#fff;
              
              // bottom:100%;
          }
        .rotating-text b{
            color:  #fff;
            font-size: xxx-large;

        }  
        
        .spot-top h3{
          position:relative;
          top:50%;
          right:30%;
        }
        

        .spot-top{
          width:100%;
          height:85vh;
          display:flex;
          justify-content : center;
          transition:backgroundimage 0.5s ease;
        }
        .video-bg {
          position: absolute;
          left: 0;
          width: 100%;
          height: 85vh;
          object-fit: cover;
          opacity:0.8;
          // top: 13%;
        }
        .spot-bottom{
          // height: 15vh
        }
        .top-left{
          background-color: #344e59;
          overflow: hidden;
          transition-duration:0.4s;
        } 
        .top-middle{
          background-color: green;
          overflow: hidden;
          transition-duration:0.4s;
        } 
        .top-right{
          background-color: blue;
          overflow: hidden;
          transition-duration:0.4s;
        }    
        .icon-list{
          width:90%;
          height:100%;
          margin:auto;
        }
        .icon-list ul{
          display:flex;
          list-style:none;
          justify-content:space-between;
          margin:auto;
        }
        .icon-list ul li{
          height:100%;
          width:103px;
          margin-left:2px;
          display:flex;
          justify-content:center;
        }
        .icon-list ul li img{
          transition:transform 0.3s ease;
          margin:auto;
        }
        
        .popup-bubble {
          display: none;
          margin-top: 76px;
          position: absolute;
          padding: 5px;
          border-radius: 5px;
          font-size: 13px;
          color:#fff;
          z-index:6;
      }
        .icon-list ul li img:hover +.popup-bubble{
            display:block;
            
          }
          .icon-list ul li:hover img {
            cursor:pointer;
            transform:scale(1.3);
          }

          @media (max-width:560px){
            .spot-top{
             height :70vh;
            }
            .video-bg{
              height:40vh;
            }
            .main-spotlight{
              height:40vh;
            }
          }
          `;

export default Spotlight;
