import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Helmet } from "react-helmet";
import DestinationBanner from "../Images/Destination/pexels-coarse-+fine-16773106.jpg";
import Footer from "../Home/Footer";
import axios from "axios";

export default function DestinationHome() {
  const [DestinationItems, setDestinationItems] = useState([]);

  const handleExploreClick = (id) => {
    window.open(`/DestinationPlace#${id}`, "_self");
  };
  useEffect(() => {
    axios
      .get("https://thetourpilot.com/resources/json/DestinationItems.json")
      .then((response) => {
        setDestinationItems(response.data);
      })
      .catch((error) => {
        console.log("Error fetching data");
      });
  }, []);

  return (
    <>
      <style>{css}</style>
      <Helmet>The Tour Pilot | Destinations</Helmet>
      <Navbar />
      <div
        className="Destination-banner-page"
        style={{ backgroundImage: `url(${DestinationBanner})` }}
      ></div>
      <div className="Destination-content-page">
        <h3>Destinations</h3>
      </div>
      <div className="Destination-content-body">
        <ul className="destination-body-ul">
          {DestinationItems.map((place, index) => {
            return (
              <li className="destination-body-li" key={index}>
                <div className="dest-li-img">
                  <img
                    src={require(`../Images/Destination/${place.image}`)}
                    alt="IMG"
                  />
                </div>
                <h3 className="dest-li-title">{place.title}</h3>
                <div className="dest-li-button-field">
                  {/* <a href="/15"> */}
                  <button
                    onClick={() => {
                      handleExploreClick(place.id);
                    }}
                    value={place.id}
                  >
                    Explore
                  </button>
                  {/* </a> */}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <Footer />
    </>
  );
}
const css = `
.Destination-banner-page{
    height: 75vh;
    width:100%;
    background-color:#344e59;
    background-position:top;
    background-size:cover;
}
.Destination-content-page h3{
    color: #fff;
    width:80%;
    font-size:50px;
    text-align:left;
    margin:0 auto;
}
.Destination-content-page{
    height:100%;
    width:100%;
    background-color:#344e59;
    padding-top:30px;
}
.Destination-content-body{
    height:100%;
    width:100%;
    background-color:#344e59;
    padding-top:30px;
    padding-bottom:30px;
}
.destination-body-ul{
    list-style:none;
    width:80%;
    display:grid;
    grid-template-columns: auto auto auto;
    gap:20px;
    margin:auto;
}
.destination-body-li{
    background-color:#344e59;
    width:32%;
    width: 400px;
    height: 440px;
    transition: box-shadow 0.3s ease;
}
.destination-body-li:hover{
    box-shadow: 0 0 10px 0 #000;
    background-color:#2d3e45
}
.dest-li-img {
    overflow:hidden;
}
.destination-body-li img{
    width: 100%;
    height: 300px;
    transition: transform 0.3s ease ; 
}
.destination-body-li:hover img{
    transform: scale(1.143);
}
  
.dest-li-button-field{
    display:flex;
    flex-wrap:wrap;
    height:10vh;
    width:100%;
    padding: 1% 2%;
}
.dest-li-button-field button{
  height: 40px;
  width: 114px;
  background:linear-gradient(to right,#344e59 50% , white 50%);
  background-size: 200% 100%;
  background-position:right bottom;
  font-size:18px;
  font-weight:bold;
  cursor:pointer;
  border:none;
  transition:all 0.5s ease ;
  border-radius:10px;
}
.dest-li-button-field button:hover{
  background-position:left bottom;
  color:#fff;
  box-shadow: 0 0 10px 0 #000;
background-color:#2d3e45
}
.dest-li-title{
    color:#fff;
    font-size:30px;
    padding: 3% 2%;
}

@media (max-width:576px){
  .Destination-banner-page{
    height: 40vh;
  }
  .Destination-content-page h3{
    font-size:30px;
  }
  .destination-body-ul{
    grid-template-columns: auto;
    gap:20px;
  }
  .destination-body-li{
    width: 270px;
    height: 310px;
  }
  .destination-body-li img{
    height: 210px;
  }
  .dest-li-title{
    font-size:20px
  }
  .dest-li-button-field button{
    height: 32px;
    width: 85px;
    font-size:15px;
  }
  .destination-body-ul{
  width: 85%;
  }

 
}
`;
