import React from "react";
import Navbar from "../../Navbar/Navbar";
import { Helmet } from "react-helmet";
import BannerImage from "../../Images/TourPage/KochiWalking/Banner/theyyam.jpg";
import Footer from "../../Home/Footer";
import { TabList, Tabs, Tab, TabPanel } from "react-tabs";
import Booking from "../Booking";
import FAQ from "../FAQ";
// import 'react-tabs/style/react-tabs.css';
// page-id = 2

export default function KochiWalking() {
  return (
    <>
      <Helmet>
        <title>The Tour Pilot | Tours</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <style>{css}</style>
      <Navbar />

      <div
        className="TourPage-banner"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="TourPage-Title">
          <h1>
            Historical Charm Unveiled: Fort Kochi and Mattancherry Walking Tour
          </h1>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>OVERVIEW</Tab>
          <Tab>ITINERARY</Tab>
          {/* <Tab>ACCOMMODATION</Tab> */}
          <Tab>GALLERY</Tab>
          <Tab>FAQ</Tab>
          <Booking />
          {/* <Tab><button>BOOK/ENQUIRE</button></Tab> */}
        </TabList>
        <TabPanel>
          <div className="TourPage-Overview">
            <div className="AboutTour">
              <div className="AboutTour-content wrapper">
                <h6>About Tour</h6>
                <p>
                  Exploring Kochi on a walking tour can be a delightful
                  experience, as the city is known for its rich history, diverse
                  culture, and stunning architecture. It is also a sensory
                  journey through time and culture, where every step unveils a
                  new layer of history, and every bite of cuisine is a burst of
                  authentic flavors. It's a memorable exploration of a city that
                  seamlessly blends its storied past with its vibrant present,
                  leaving you with a deeper appreciation of the enchanting
                  tapestry of Kochi's heritage. This walking tour will allow you
                  to explore the historical and cultural gems of Kochi while
                  enjoying its delicious cuisine and picturesque landscapes.
                  Remember to wear comfortable walking shoes, stay hydrated, and
                  respect local customs and traditions during your tour. Enjoy
                  your day in Kochi!
                </p>
              </div>
            </div>
            <div className="Highlights">
              <div className="Highlights-content wrapper ">
                <h6>Highlights</h6>
                <ul>
                  <li>
                    Multicultural Atmosphere: Immerse yourself in the city's
                    multicultural ambiance, where European, Indian, Jewish, and
                    other influences have left their mark on the city's
                    architecture, cuisine, and culture.
                  </li>
                  <li>
                    Historical Landmarks: Throughout your tour, you'll encounter
                    historical landmarks, colonial architecture, and museums
                    that offer insights into Kochi's diverse history and
                    heritage.
                  </li>
                  <li>
                    Local Markets: Explore local markets and shops, where you
                    can purchase spices, souvenirs, and handicrafts that
                    showcase the region's artistic talents.
                  </li>
                  <li>
                    Paradesi Synagogue: Visit the Paradesi Synagogue, the oldest
                    active synagogue in the Commonwealth, located in the heart
                    of Mattancherry. Admire its distinctive blue-and-white tiles
                    and learn about the Jewish heritage of the area.
                  </li>
                  <li>
                    Local Encounters: Along the way, interact with local
                    artisans, observe traditional craft-making, and savor the
                    flavors of Kerala cuisine at local eateries. Engage with the
                    diverse cultures that have shaped these neighborhoods.
                  </li>
                </ul>
              </div>
            </div>
            <div className="Inclusions/Exclusions">
              <div className="AboutTour-ul">
                <div className="Inclusions">
                  <h6>Inclusions</h6>
                  <ul>
                    <li>Accommodation on twin share</li>
                    <li>Professional tour leader</li>
                    <li>Mountain bikes</li>
                    <li>Meals as indicated (ethnic food)</li>
                    <li>Refreshment and mineral water during the activity</li>
                    <li>Back up mini bus for entire tour</li>
                    <li>Airport pick up & drop</li>
                    <li>Licenses and govt taxes</li>
                  </ul>
                </div>
                <div className="Exclusions">
                  <h6>Exclusions</h6>
                  <ul>
                    <li>Flights</li>
                    <li>Travel Insurance</li>
                    <li>Tips</li>
                    <li>Laundry</li>
                    <li>Beverages</li>
                    <li>Personal Shopping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Itinerary">
            <div className="Itinerary-content wrapper ">
              <ul>
                <li>
                  <h6>Start at Fort Kochi Beach:</h6>
                  <p>
                    Begin your day at the iconic Fort Kochi Beach, where you can
                    witness local fishermen using Chinese fishing nets. Enjoy
                    the serene morning atmosphere and take in the beautiful view
                    of the Arabian Sea.
                  </p>
                </li>
                <li>
                  <h6>St. Francis Church:</h6>
                  <p>
                    Head towards St. Francis Church, one of the oldest European
                    churches in India. It's a short walk from the beach. Explore
                    the historic church and learn about its significance.
                  </p>
                </li>
                <li>
                  <h6>Santa Cruz Basilica:</h6>
                  <p>
                    Walk to Santa Cruz Basilica, a magnificent church with
                    stunning Gothic architecture. Take some time to admire the
                    interior and the beautiful stained glass windows.
                  </p>
                </li>
                <li>
                  <h6>Bazar Road</h6>
                  <p>
                    Kochi Bazar Road, also known as Bazaar Road, is a bustling
                    and vibrant street located in the heart of Mattancherry, a
                    historic neighborhood in Kochi, Kerala, India. This road is
                    renowned for its eclectic mix of shops, markets, and local
                    attractions. As you walk through Kochi Bazar Road, you'll
                    encounter a blend of traditional and modern elements that
                    reflect the unique character of this area.
                  </p>
                </li>
                <li>
                  <h6>Dutch Palace (Mattancherry Palace):</h6>
                  <p>
                    Stroll to the Dutch Palace, also known as Mattancherry
                    Palace. This 16th-century palace is known for its unique
                    Kerala mural paintings. Explore the museum inside to learn
                    about the history and culture of Kerala.
                  </p>
                </li>
                <li>
                  <h6>Jew Town and Synagogue:</h6>
                  <p>
                    After lunch, walk to Jew Town in Mattancherry. Visit the
                    Paradesi Synagogue, one of the oldest active synagogues in
                    the Commonwealth of Nations.
                  </p>
                </li>
                <li>
                  <h6>Back to FortKochi</h6>
                  <p>
                    Head back to Fort Kochi Beach to see the Chinese fishing
                    nets in action again.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="galleryContainer">
            <div className="gallery_box ">
              <div className="gallery">
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/Kochi.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/kochin2.jpeg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/Untitled-2.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/net.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/Untitled-3.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
                <img
                  src={require("../../Images/TourPage/KochiWalking/Gallery/Untitled-1.jpg")}
                  alt=""
                  height={250}
                  width={400}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <FAQ />
        </TabPanel>
      </Tabs>
      <Footer />
    </>
  );
}
const css = `
    .TourPage-banner{
        background-size: cover;
        background-position:center;
        position:relative;
        height: 75vh;
        width:100%;
        background-color:#344e59;
    }
    .TourPage-Title{
        background-color:#7a876473;
        width:100%;
        position:absolute;
        bottom:0;
        color:#fff;
    }  
    
    .TourPage-Overview{
        background-color: #344e59;
        font-size: 15px;
        color: #fff;
        font-weight:bold;
    }
    h6{
        
        font-size: 30px;
    }
    p{
        padding-top:15px;
        font-size: 17px;
        font-weight:100;
    }
    .AboutTour{
        width:70%;
        height:100%;
        margin: auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Highlights{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
    }
    .Itinerary{
        height:100%;
        width:70%;
        margin:auto;
        padding-top:50px;
        padding-bottom:50px
        
    }
    .Itinerary-content ul{
        list-style:none;
        color:#fff;
        padding-top:30px;
    }
    .Itinerary-content ul Li{
        padding:10px 0px;
    }
    .Highlights ul{
        padding-top:30px;
        font-size: 17px;
        font-weight:100;
    }
    h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .react-tabs{
        background-color:#7a8764;
    }
    .react-tabs__tab-list{
        // border-bottom: 0px solid #aaa;
        //  margin: 0 0 0px; 
        //  padding: 0;
         display:flex;
         justify-content:space-between;
         width:70%;
         margin:auto;
        //  background-color:#7a8764;
         height:40px;
         font-size:15px;
         font-weight:bold;
    }
    .react-tabs__tab {
        list-style: none;
        padding: 9px 12px;
        cursor: pointer;
        transform:skewX(-12deg);
        color:#fff;     
    }
    .react-tabs__tab--selected {
        background: #526c47;
        color: #fff;
    }
    .AboutTour-ul{
      display:flex;
      justify-content:space-between;
      height:40vh;
      width:55%;
      margin:auto;
    }
    .AboutTour-ul li{
      margin-top:5px;
      font-weight:100;
  }
    .Inclusions/Exclusions{
      display:flex;
      width:100%;
    }
    @media(max-width:576px){
      .react-tabs__tab-list{
        flex-direction: column;
        height:209px; 
        width: 86%;
      }
      .AboutTour {
        padding-top: 40px;
        padding-bottom: 40px;
        width: 92%;
    }
    .AboutTour-content p{
      font-size: 12px;
    }
    .Highlights{
      padding-top: 30px;
      padding-bottom: 30px;
      width: 92%;
    }
    .TourPage-Overview {
      font-size: 10px;
    }
    .Highlights ul {
      font-size: 13px;
    }
    .AboutTour-ul {
      height: 27vh;
      width: 76%;
    }
    .AboutTour-ul ul{
      padding-left: 10px;
    }
    .TourPage-Title h1{
      font-size: 1.3em;
    }
    .Itinerary {
      width:100%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .wrapper {
      padding-right: 10px;
      padding-left: 2px;
    }
    .Itinerary-content ul{
      padding-top: 5px;
      padding-left:30px;
    }
    .Itinerary-content h6{
      font-size: 20px;
  }
  .Itinerary-content p{
    font-size: 15px;
  }
  .gallery img{
    width:300px;
    height:180px;
  }
  }
  }
`;
