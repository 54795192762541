import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar/Navbar'
import bgkayaking from '../Images/TourPage/ToursIndividual/Kayaking.jpg'
import Footer from '../Home/Footer'

export default function Kayaking() {
  return (
    <>
    <Helmet>
        <title>The Tour Pilot | Kayaking</title>
        {/* <link rel="icon" type="image/png" href="../Images/Nav/Untitled-1-02.png" /> */}
      </Helmet>
      <Navbar />
    <style>{css}</style>
    <div className='kayaking-page'>
      <div className='kayaking-page-banner' style={{backgroundImage:`url(${bgkayaking})`}}></div>
      <div className="kayaking-page-content">
      <h3>Kayaking Tours</h3>
        <ul className="kayaking-page-list-ul">
          <li className="kayaking-page-list-li">
            <div className="kayakinig-page-list-li-image">
              <img src={require("../Images/Home/Grid/Untitled-1.jpg")} alt="" />
            </div>
            <h2>Kerala Motorcycle Odyssey</h2>
            <h6>Motorcyling</h6>
            <h6>10 Places</h6>
            <p className="kayaking-page-list-li-para">
              Prepare for an unforgettable motorcycle journey through the
              picturesque landscapes and cultural treasures of Kerala, India.
            </p>
            <div className="kayaking-page-li-button-field">
              <a href="/Tours">
                <button>Explore</button>
              </a>
            </div>
          </li>
          </ul>
          </div>
    </div>
    <Footer />
    </>
  )
}

const css = `
.kayaking-page-content h3{
    color: #fff;
    padding-top:1%;
    font-size:30px;
    text-align:center;
}
.kayaking-page-list-ul{
    padding-top:100px;
}
.kayaking-page-list-li h6{
    font-size:15px;
    color: #fff;
    padding: 2px;
    padding-left: 10px;
    font-weight:lighter;
  }
  
  
  .kayaking-page-li-button-field{
    display:flex;
    flex-wrap:wrap;
    width:95%;
    padding: 3% 0;
    margin:auto;
    // justify-content:center;
    // align-content:left;
  }
  .kayaking-page-li-button-field button{
    height: 32px;
    width: 114px;
    background: linear-gradient(to right,#344e59 50% , white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    border-radius: 6px;
  
  }
  .kayaking-page-li-button-field button:hover{
    background-position:left bottom;
    color:#fff;
  }
   
.kayaking-page-banner{
    height:80vh;
    background-size: cover;
}
.kayaking-page-content{
    height:100%;
    width:100%;
    background-color:#344e59;
   padding-bottom:100px;
}
.kayaking-page-list-ul{
    width:82%;
    margin:auto;
    display:grid;
    grid-template-columns: auto auto auto;
    gap:30px;
    list-style:none;
}
@media screen and (max-width: 1290px) {
  .kayaking-page-list-ul{
    grid-template-columns: auto auto;
  }
}
.kayaking-page-list-li-image{
    height:290px;
    width:400px;
    object-fit: center;
    overflow:hidden;
}
.kayaking-page-list-ul li{
    transition: box-shadow 0.3s ease;
    width:400px;
    height: 570px;
    overflow:hidden;        
}
.kayaking-page-list-ul li:hover{
    box-shadow: 0 0 10px 0 #959bcd;
}
.kayaking-page-list-ul li:hover h2{
  color:  #d2d559;
}
.kayaking-page-list-ul li:hover h6{
color:  #d2d559;
}
.kayaking-page-list-li img{
    width:400px;
    height:290px;
    transition: transform 0.3s ease ; 
    cursor:pointer;  
    position:center; 
}

.kayaking-page-list-li:hover img{
  transform: scale(1.143);
}

.kayaking-page-list-li-para{
    color:#fff;
    font-size:15px;
    padding:10px;
    transition:transform 0.3s ease;
}
.kayaking-page-list-ul li:hover .kayaking-page-list-li-para{
  transform: scale(0.99);
  color:#d2d559;

}
.kayaking-page-content{
    padding-top:80px;
    padding-bottom:0px
}

.kayaking-page-list-li h2{
    color:#fff;
    font-size:20px;
    padding:10px;
    padding-top:30px;
}

`
